import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import TicketCard from '../../Component/TicketCard';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setTicketsAction,
  setTicketsSuccessAction,
  setTicketsFailureAction,
  setCurrentTicketAction,
  setSaveTicketOrderAction,
  setSaveTicketOrderSuccessAction,
  setSaveTicketOrderFailureAction,
} from '../../Redux/Actions/tickets';
import DragAndDrop from '../DragAndDrop';

function Tickets(props) {
  const {
    accessToken,
    fetched,
    tickets,
    loading,
    error,
    setScreenAction,
    setTicketsAction,
    setTicketsSuccessAction,
    setTicketsFailureAction,
    setCurrentTicketAction,
    setSaveTicketOrderAction,
    setSaveTicketOrderSuccessAction,
    setSaveTicketOrderFailureAction,
  } = props;

  const [localTickets, setLocalTickets] = useState(tickets);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalTickets(tickets);
  }, [tickets]);

  useEffect(() => {
    if (!fetched) {
      setTicketsAction();
      fetch(`${config.api}/api/tickets`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setTicketsSuccessAction(result.data);
          },
          (error) => {
            setTicketsFailureAction(error);
          }
        );
    }
  }, [fetched, setTicketsAction, setTicketsFailureAction, setTicketsSuccessAction]);

  const newTicket = () => {
    setCurrentTicketAction(null);
    setScreenAction('new_ticket');
  }

  const saveOrder = () => {
    setSaveTicketOrderAction();

    fetch(`${config.api}/api/tickets/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ ticketIds: localTickets }),
    })
      .then(res => res.json())
      .then(
        (result) => {
            if (result.error) {
                // result error
                setSaveTicketOrderFailureAction(result.error);
                return;
            }

            setSaveTicketOrderSuccessAction(localTickets);
            setShowOrderSaveButton(false);
        },
        (error) => {
            // connection errors
            setSaveTicketOrderFailureAction(error);
        }
    );
  }

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={newTicket}>New Ticket</Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localTickets}
        setLocal={setLocalTickets}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='ticket'
        Card={TicketCard}
      />
      {error ? <Error message='Could not get tickets' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.tickets.fetched,
    loading: state.tickets.loading,
    error: state.tickets.error,
    tickets: state.tickets.allIds,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setTicketsAction,
  setTicketsSuccessAction,
  setTicketsFailureAction,
  setCurrentTicketAction,
  setSaveTicketOrderAction,
  setSaveTicketOrderSuccessAction,
  setSaveTicketOrderFailureAction,
})(Tickets);

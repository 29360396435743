import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import CastMemberCard from '../../Component/CastMemberCard';
import config from '../../config';
import { setScreenAction } from '../../Redux/Actions/app';
import { setCurrentShowAction } from '../../Redux/Actions/shows'
import {
  setCastMembersAction,
  setCastMembersSuccessAction,
  setCastMembersFailureAction,
  setCurrentCastMemberAction,
  setSaveCastOrderAction,
  setSaveCastOrderSuccessAction,
  setSaveCastOrderFailureAction,
} from '../../Redux/Actions/castMembers';
import {
  editShowAction,
  editShowSuccessAction,
  editShowFailureAction,
} from '../../Redux/Actions/shows';
import DragAndDrop from '../DragAndDrop';

function CastMembers(props) {
  const {
    classes,
    accessToken,
    showId,
    fetched,
    castList,
    loading,
    error,
    setHideCastMembers,
    setScreenAction,
    setCastMembersAction,
    setCastMembersSuccessAction,
    setCastMembersFailureAction,
    setCurrentCastMemberAction,
    setCurrentShowAction,
    setSaveCastOrderAction,
    setSaveCastOrderSuccessAction,
    setSaveCastOrderFailureAction,
  } = props;

  const [localCastList, setLocalCastList] = useState(castList);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalCastList(castList.map(castMember => castMember._id));
  }, [castList]);

  useEffect(() => {
    if (!fetched) {
      setCastMembersAction();
      fetch(`${config.api}/api/show/${showId}/cast-members`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setCastMembersSuccessAction(showId, result.data);
          },
          (error) => {
            setCastMembersFailureAction(error);
          }
        );
    }
  }, [fetched, showId, setCastMembersAction, setCastMembersSuccessAction, setCastMembersFailureAction]);

  const newCastMember = () => {
    setCurrentShowAction(showId);
    setCurrentCastMemberAction(null);
    setScreenAction('new_cast_member');
  }

  const saveOrder = () => {
    setSaveCastOrderAction();

    fetch(`${config.api}/api/cast-members/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        castMemberIds: localCastList,
        showUuid: showId,
      }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            setSaveCastOrderFailureAction(result.error);
            return;
          }

          setSaveCastOrderSuccessAction(localCastList);
          setShowOrderSaveButton(false);
        },
        (error) => {
          // connection errors
          setSaveCastOrderFailureAction(error);
        }
      );
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
            <Grid item>
              <Button variant="contained" onClick={saveOrder}>Save Order</Button>
            </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={() => setHideCastMembers(true)} color='primary'>Hide Cast Members</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={newCastMember}>New Cast Member</Button>
        </Grid>
      </Grid>
    );
  };

  if (loading || !fetched) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  return (
    <Container className={classes.root}>
      {renderButtons()}
      <DragAndDrop
        local={localCastList}
        setLocal={setLocalCastList}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='cast'
        Card={CastMemberCard}
      />
      {error ? <Error message='Could not get shows' alignment='center' /> : ''}
    </Container>
  );
}

const style = {
  root: {
    marginTop: 5,
  },
}

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.castMembers.fetched[props.showId] || false,
    castList: state.shows.byIds[props.showId].castList,
    loading: state.castMembers.loading,
    error: state.castMembers.error,
    show: state.shows.byIds[props.showId]
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setCastMembersAction,
  setCastMembersSuccessAction,
  setCastMembersFailureAction,
  setCurrentCastMemberAction,
  setCurrentShowAction,
  editShowAction,
  editShowSuccessAction,
  editShowFailureAction,
  setSaveCastOrderAction,
  setSaveCastOrderSuccessAction,
  setSaveCastOrderFailureAction,
})(withStyles(style)(CastMembers));

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  deleteProductionMemberAction,
  deleteProductionMemberSuccessAction,
  deleteProductionMemberFailureAction,
  setCurrentProductionMemberAction,
} from '../../Redux/Actions/productionMembers';

function ProductionMemberCard(props) {
  const {
    index,
    classes,
    productionMember,
    deleting,
    error,
    accessToken,
    deleteProductionMemberAction,
    deleteProductionMemberSuccessAction,
    deleteProductionMemberFailureAction,
    setCurrentProductionMemberAction,
    setScreenAction,
  } = props;

  const deleteProductionMember = () => {
    deleteProductionMemberAction();

    fetch(`${config.api}/api/production-member/${productionMember._id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          deleteProductionMemberSuccessAction(productionMember);
        },
        (error) => {
          deleteProductionMemberFailureAction(error);
        }
      );
  }

  const editProductionMember = () => {
    setCurrentProductionMemberAction(productionMember._id);
    setScreenAction('new_production_member');
  }

  if (!productionMember) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={editProductionMember} disabled={deleting}>
            Edit
                    </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={deleteProductionMember} color='secondary' startIcon={<DeleteIcon />} disabled={deleting}>
            Delete
                    </Button>
          {deleting ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
        <Grid item xs={12}>
          <Typography>{productionMember.name}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },

  editButton: {
    marginRight: 5
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    productionMember: state.productionMembers.byIds[props.id],
    deleting: state.productionMembers.deleting,
    error: state.productionMembers.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteProductionMemberAction,
  deleteProductionMemberSuccessAction,
  deleteProductionMemberFailureAction,
  setCurrentProductionMemberAction,
})(withStyles(style)(ProductionMemberCard));

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Avatar, Typography, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../config';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  deleteMemberAction,
  deleteMemberSuccessAction,
  deleteMemberFailureAction,
  setCurrentMemberAction,
} from '../../Redux/Actions/members';

function MemberCard(props) {
  const {
    index,
    classes,
    member,
    loading,
    error,
    accessToken,
    deleteMemberAction,
    deleteMemberSuccessAction,
    deleteMemberFailureAction,
    setCurrentMemberAction,
    setScreenAction,
  } = props;

  const deleteMember = (id) => {
    deleteMemberAction();

    fetch(`${config.api}/api/member/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            deleteMemberFailureAction(result.error);
            return;
          }
          deleteMemberSuccessAction(member);
        },
        (error) => {
          deleteMemberFailureAction(error);
        }
      );
  }

  const editMember = (uuid) => {
    setCurrentMemberAction(uuid);
    setScreenAction('new_member');
  }

  if (!member) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={() => editMember(member._id)} disabled={loading}>
            Edit
                    </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => deleteMember(member._id)} color='secondary' startIcon={<DeleteIcon />} disabled={loading}>
            Delete
                    </Button>
          {loading ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar alt={member.name} src={`${member.image}?${new Date().getTime()}`} className={classes.large} />
        <Typography>{member.name}</Typography>
        <Typography>{member.position}</Typography>
        <Typography>{member.email}</Typography>
      </Grid>
      <div dangerouslySetInnerHTML={{ __html: member.bio }} />
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },

  editButton: {
    marginRight: 5
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    member: state.members.byIds[props.id],
    loading: state.members.deleting,
    error: state.members.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteMemberAction,
  deleteMemberSuccessAction,
  deleteMemberFailureAction,
  setCurrentMemberAction,
})(withStyles(style)(MemberCard));

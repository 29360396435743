import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
    loggingInAction,
    logInSuccessAction,
    logInFailureAction,
    logInUpdateSuccessAction,
    logInUpdateFailureAction,
} from '../../Redux/Actions/auth';
import Error from '../../Component/Error';
import config from '../../config';
import { Container, TextField, Grid, Card, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { runOnEnter } from '../../helper';
import { setScreenAction } from '../../Redux/Actions/app';

function Login(props) {
    const {
        loggedIn,
        classes,
        loading,
        error,
        loggingInAction,
        logInSuccessAction,
        logInFailureAction,
        logInUpdateSuccessAction,
        logInUpdateFailureAction,
        setScreenAction,
        accessToken,
    } = props;

    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = () => {
        if (loggedIn) {
            loggingInAction();
            fetch(`${config.api}/api/login`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'x-access-token': accessToken,
                },
                body: JSON.stringify({ id: accessToken, name, password }),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            logInUpdateFailureAction(result.error);
                            return;
                        }

                        logInUpdateSuccessAction(result.data)
                        setScreenAction('');
                    },
                    (error) => {
                        logInUpdateFailureAction(error);
                    }
                );

        } else {
            loggingInAction();
            fetch(`${config.api}/api/login`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name, password }),
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            logInFailureAction(result.error);
                            return;
                        }

                        logInSuccessAction(result.data.access_token)
                    },
                    (error) => {
                        logInFailureAction(error);
                    }
                );
        }
    }

    return (
        <Container>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Card className={classes.card}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <TextField
                            variant="outlined"
                            id="name"
                            label="Name"
                            className={classes.textField}
                            value={name}
                            onChange={event => setName(event.target.value)}
                            onKeyDown={(e) => runOnEnter(e, onSubmit)}
                        />
                        <TextField
                            variant="outlined"
                            type="password"
                            id="password"
                            label="Password"
                            className={classes.textField}
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            onKeyDown={(e) => runOnEnter(e, onSubmit)}
                        />
                        <Button variant="contained" color='primary' onClick={onSubmit} className={classes.textField} disabled={loading}>{loggedIn ? 'Update' : 'Login'}</Button>
                        {error ? <Error message={loggedIn ? 'There was a problem updating' : 'Name or password is incorrect'} alignment={'center'} /> : ''}
                    </Grid>
                </Card>
            </Grid>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = state => {
    return {
      loggedIn: state.auth.loggedIn,
      accessToken: state.auth.accessToken,
      loading: state.auth.loading,
      error: state.auth.error,
    };
};


export default connect(mapStateToProps, {
    loggingInAction,
    logInSuccessAction,
    logInFailureAction,
    logInUpdateSuccessAction,
    logInUpdateFailureAction,
    setScreenAction,
})(withStyles(style)(Login));

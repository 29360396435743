import { combineReducers } from 'redux';
import auth from './auth';
import app from './app';
import upcomings from './upcomings';
import members from './members';
import seasons from './seasons';
import photoGallery from './photoGallery';
import shows from './shows';
import productionMembers from './productionMembers';
import castMembers from './castMembers';
import photos from './photos';
import tickets from './tickets';
import goals from './goals';
import newses from './newses';
import auditions from './auditions';

export default combineReducers({
    auth,
    app,
    upcomings,
    members,
    seasons,
    photoGallery,
    shows,
    productionMembers,
    castMembers,
    photos,
    tickets,
    goals,
    newses,
    auditions,
 });

// App actions
export const SET_SCREEN = 'SET_SCREEN';
export const ADD_DISABLED_DND = 'ADD_DISABLED_DND';
export const REMOVE_DISABLED_DND = 'REMOVE_DISABLED_DND';
export const CLEAR_DISABLED_DND = 'CLEAR_DISABLED_DND';

// Log in actions
export const LOGGING_IN = 'LOGGING_IN';
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_FAILURE = 'LOG_IN_FAILURE';
export const LOG_IN_UPDATE_SUCCESS = 'LOG_IN_UPDATE_SUCCESS';
export const LOG_IN_UPDATE_FAILURE = 'LOG_IN_UPDATE_FAILURE';

// Upcoming actions
export const SET_CURRENT_UPCOMING = 'SET_CURRENT_UPCOMING';

export const SET_UPCOMINGS = 'SET_UPCOMINGS';
export const SET_UPCOMINGS_SUCCESS = 'SET_UPCOMINGS_SUCCESS';
export const SET_UPCOMINGS_FAILURE = 'SET_UPCOMINGS_FAILURE';

export const ADD_UPCOMING = 'ADD_UPCOMING';
export const ADD_UPCOMING_SUCCESS = 'ADD_UPCOMING_SUCCESS';
export const ADD_UPCOMING_FAILURE = 'ADD_UPCOMING_FAILURE';

export const EDIT_UPCOMING = 'EDIT_UPCOMING';
export const EDIT_UPCOMING_SUCCESS = 'EDIT_UPCOMING_SUCCESS';
export const EDIT_UPCOMING_FAILURE = 'EDIT_UPCOMING_FAILURE';

export const DELETE_UPCOMING = 'DELETE_UPCOMING';
export const DELETE_UPCOMING_SUCCESS = 'DELETE_UPCOMING_SUCCESS';
export const DELETE_UPCOMING_FAILURE = 'DELETE_UPCOMING_FAILURE';

export const SAVE_UPCOMING_ORDER = 'SAVE_UPCOMING_ORDER';
export const SAVE_UPCOMING_ORDER_SUCCESS = 'SAVE_UPCOMING_ORDER_SUCCESS';
export const SAVE_UPCOMING_ORDER_FAILURE = 'SAVE_UPCOMING_ORDER_FAILURE';

// Member actions
export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER';

export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_MEMBERS_SUCCESS = 'SET_MEMBERS_SUCCESS';
export const SET_MEMBERS_FAILURE = 'SET_MEMBERS_FAILURE';

export const ADD_MEMBER = 'ADD_MEMBER';
export const ADD_MEMBER_SUCCESS = 'ADD_MEMBER_SUCCESS';
export const ADD_MEMBER_FAILURE = 'ADD_MEMBER_FAILURE';

export const EDIT_MEMBER = 'EDIT_MEMBER';
export const EDIT_MEMBER_SUCCESS = 'EDIT_MEMBER_SUCCESS';
export const EDIT_MEMBER_FAILURE = 'EDIT_MEMBER_FAILURE';

export const DELETE_MEMBER = 'DELETE_MEMBER';
export const DELETE_MEMBER_SUCCESS = 'DELETE_MEMBER_SUCCESS';
export const DELETE_MEMBER_FAILURE = 'DELETE_MEMBER_FAILURE';

export const SAVE_MEMBER_ORDER = 'SAVE_MEMBER_ORDER';
export const SAVE_MEMBER_ORDER_SUCCESS = 'SAVE_MEMBER_ORDER_SUCCESS';
export const SAVE_MEMBER_ORDER_FAILURE = 'SAVE_MEMBER_ORDER_FAILURE';

// Season actions
export const SET_CURRENT_SEASON = 'SET_CURRENT_SEASON';

export const SET_SEASONS = 'SET_SEASONS';
export const SET_SEASONS_SUCCESS = 'SET_SEASONS_SUCCESS';
export const SET_SEASONS_FAILURE = 'SET_SEASONS_FAILURE';

export const ADD_SEASON = 'ADD_SEASON';
export const ADD_SEASON_SUCCESS = 'ADD_SEASON_SUCCESS';
export const ADD_SEASON_FAILURE = 'ADD_SEASON_FAILURE';

export const EDIT_SEASON = 'EDIT_SEASON';
export const EDIT_SEASON_SUCCESS = 'EDIT_SEASON_SUCCESS';
export const EDIT_SEASON_FAILURE = 'EDIT_SEASON_FAILURE';

export const DELETE_SEASON = 'DELETE_SEASON';
export const DELETE_SEASON_SUCCESS = 'DELETE_SEASON_SUCCESS';
export const DELETE_SEASON_FAILURE = 'DELETE_SEASON_FAILURE';

export const SAVE_SEASON_ORDER = 'SAVE_SEASON_ORDER';
export const SAVE_SEASON_ORDER_SUCCESS = 'SAVE_SEASON_ORDER_SUCCESS';
export const SAVE_SEASON_ORDER_FAILURE = 'SAVE_SEASON_ORDER_FAILURE';

// Photo gallery actions
export const SET_CURRENT_PHOTO_GALLERY = 'SET_CURRENT_PHOTO_GALLERY';

export const SET_PHOTO_GALLERIES = 'SET_PHOTO_GALLERIES';
export const SET_PHOTO_GALLERIES_SUCCESS = 'SET_PHOTO_GALLERIES_SUCCESS';
export const SET_PHOTO_GALLERIES_FAILURE = 'SET_PHOTO_GALLERIES_FAILURE';

export const ADD_PHOTO_GALLERY = 'ADD_PHOTO_GALLERY';
export const ADD_PHOTO_GALLERY_SUCCESS = 'ADD_PHOTO_GALLERY_SUCCESS';
export const ADD_PHOTO_GALLERY_FAILURE = 'ADD_PHOTO_GALLERY_FAILURE';

export const EDIT_PHOTO_GALLERY = 'EDIT_PHOTO_GALLERY';
export const EDIT_PHOTO_GALLERY_SUCCESS = 'EDIT_PHOTO_GALLERY_SUCCESS';
export const EDIT_PHOTO_GALLERY_FAILURE = 'EDIT_PHOTO_GALLERY_FAILURE';

export const DELETE_PHOTO_GALLERY = 'DELETE_PHOTO_GALLERY';
export const DELETE_PHOTO_GALLERY_SUCCESS = 'DELETE_PHOTO_GALLERY_SUCCESS';
export const DELETE_PHOTO_GALLERY_FAILURE = 'DELETE_PHOTO_GALLERY_FAILURE';

export const SAVE_PHOTO_GALLERY_ORDER = 'SAVE_PHOTO_GALLERY_ORDER';
export const SAVE_PHOTO_GALLERY_ORDER_SUCCESS = 'SAVE_PHOTO_GALLERY_ORDER_SUCCESS';
export const SAVE_PHOTO_GALLERY_ORDER_FAILURE = 'SAVE_PHOTO_GALLERY_ORDER_FAILURE';

// Show actions
export const SET_CURRENT_SHOW = 'SET_CURRENT_SHOW';

export const SET_SHOWS = 'SET_SHOWS';
export const SET_SHOWS_SUCCESS = 'SET_SHOWS_SUCCESS';
export const SET_SHOWS_FAILURE = 'SET_SHOWS_FAILURE';

export const ADD_SHOW = 'ADD_SHOW';
export const ADD_SHOW_SUCCESS = 'ADD_SHOW_SUCCESS';
export const ADD_SHOW_FAILURE = 'ADD_SHOW_FAILURE';

export const EDIT_SHOW = 'EDIT_SHOW';
export const EDIT_SHOW_SUCCESS = 'EDIT_SHOW_SUCCESS';
export const EDIT_SHOW_FAILURE = 'EDIT_SHOW_FAILURE';

export const DELETE_SHOW = 'DELETE_SHOW';
export const DELETE_SHOW_SUCCESS = 'DELETE_SHOW_SUCCESS';
export const DELETE_SHOW_FAILURE = 'DELETE_SHOW_FAILURE';

export const SAVE_SHOW_ORDER = 'SAVE_SHOW_ORDER';
export const SAVE_SHOW_ORDER_SUCCESS = 'SAVE_SHOW_ORDER_SUCCESS';
export const SAVE_SHOW_ORDER_FAILURE = 'SAVE_SHOW_ORDER_FAILURE';

export const SET_HIDE_SHOWS = 'SET_HIDE_SHOWS';

// Production member actions
export const SET_CURRENT_PRODUCTION_MEMBER = 'SET_CURRENT_PRODUCTION_MEMBER';

export const SET_PRODUCTION_MEMBERS = 'SET_PRODUCTION_MEMBERS';
export const SET_PRODUCTION_MEMBERS_SUCCESS = 'SET_PRODUCTION_MEMBERS_SUCCESS';
export const SET_PRODUCTION_MEMBERS_FAILURE = 'SET_PRODUCTION_MEMBERS_FAILURE';

export const ADD_PRODUCTION_MEMBER = 'ADD_PRODUCTION_MEMBER';
export const ADD_PRODUCTION_MEMBER_SUCCESS = 'ADD_PRODUCTION_MEMBER_SUCCESS';
export const ADD_PRODUCTION_MEMBER_FAILURE = 'ADD_PRODUCTION_MEMBER_FAILURE';

export const EDIT_PRODUCTION_MEMBER = 'EDIT_PRODUCTION_MEMBER';
export const EDIT_PRODUCTION_MEMBER_SUCCESS = 'EDIT_PRODUCTION_MEMBER_SUCCESS';
export const EDIT_PRODUCTION_MEMBER_FAILURE = 'EDIT_PRODUCTION_MEMBER_FAILURE';

export const DELETE_PRODUCTION_MEMBER = 'DELETE_PRODUCTION_MEMBER';
export const DELETE_PRODUCTION_MEMBER_SUCCESS = 'DELETE_PRODUCTION_MEMBER_SUCCESS';
export const DELETE_PRODUCTION_MEMBER_FAILURE = 'DELETE_PRODUCTION_MEMBER_FAILURE';

export const SAVE_PRODUCTION_ORDER = 'SAVE_PRODUCTION_ORDER';
export const SAVE_PRODUCTION_ORDER_SUCCESS = 'SAVE_PRODUCTION_ORDER_SUCCESS';
export const SAVE_PRODUCTION_ORDER_FAILURE = 'SAVE_PRODUCTION_ORDER_FAILURE';

export const SET_HIDE_PRODUCTION_MEMBERS = 'SET_HIDE_PRODUCTION_MEMBERS';

// Cast member actions
export const SET_CURRENT_CAST_MEMBER = 'SET_CURRENT_CAST_MEMBER';

export const SET_CAST_MEMBERS = 'SET_CAST_MEMBERS';
export const SET_CAST_MEMBERS_SUCCESS = 'SET_CAST_MEMBERS_SUCCESS';
export const SET_CAST_MEMBERS_FAILURE = 'SET_CAST_MEMBERS_FAILURE';

export const ADD_CAST_MEMBER = 'ADD_CAST_MEMBER';
export const ADD_CAST_MEMBER_SUCCESS = 'ADD_CAST_MEMBER_SUCCESS';
export const ADD_CAST_MEMBER_FAILURE = 'ADD_CAST_MEMBER_FAILURE';

export const EDIT_CAST_MEMBER = 'EDIT_CAST_MEMBER';
export const EDIT_CAST_MEMBER_SUCCESS = 'EDIT_CAST_MEMBER_SUCCESS';
export const EDIT_CAST_MEMBER_FAILURE = 'EDIT_CAST_MEMBER_FAILURE';

export const DELETE_CAST_MEMBER = 'DELETE_CAST_MEMBER';
export const DELETE_CAST_MEMBER_SUCCESS = 'DELETE_CAST_MEMBER_SUCCESS';
export const DELETE_CAST_MEMBER_FAILURE = 'DELETE_CAST_MEMBER_FAILURE';

export const SAVE_CAST_MEMBER_ORDER = 'SAVE_CAST_MEMBER_ORDER';
export const SAVE_CAST_MEMBER_ORDER_SUCCESS = 'SAVE_CAST_MEMBER_ORDER_SUCCESS';
export const SAVE_CAST_MEMBER_ORDER_FAILURE = 'SAVE_CAST_MEMBER_ORDER_FAILURE';

export const SET_HIDE_CAST_MEMBERS = 'SET_HIDE_CAST_MEMBERS';

// Photo actions
export const SET_CURRENT_PHOTO = 'SET_CURRENT_PHOTO';

export const SET_PHOTOS = 'SET_PHOTOS';
export const SET_PHOTOS_SUCCESS = 'SET_PHOTOS_SUCCESS';
export const SET_PHOTOS_FAILURE = 'SET_PHOTOS_FAILURE';

export const ADD_PHOTO = 'ADD_PHOTO';
export const ADD_PHOTO_SUCCESS = 'ADD_PHOTO_SUCCESS';
export const ADD_PHOTO_FAILURE = 'ADD_PHOTO_FAILURE';

export const EDIT_PHOTO = 'EDIT_PHOTO';
export const EDIT_PHOTO_SUCCESS = 'EDIT_PHOTO_SUCCESS';
export const EDIT_PHOTO_FAILURE = 'EDIT_PHOTO_FAILURE';

export const DELETE_PHOTO = 'DELETE_PHOTO';
export const DELETE_PHOTO_SUCCESS = 'DELETE_PHOTO_SUCCESS';
export const DELETE_PHOTO_FAILURE = 'DELETE_PHOTO_FAILURE';

export const SAVE_PHOTO_ORDER = 'SAVE_PHOTO_ORDER';
export const SAVE_PHOTO_ORDER_SUCCESS = 'SAVE_PHOTO_ORDER_SUCCESS';
export const SAVE_PHOTO_ORDER_FAILURE = 'SAVE_PHOTO_ORDER_FAILURE';

export const SET_HIDE_PHOTOS = 'SET_HIDE_PHOTOS';

// Ticket actions
export const SET_TICKETS = 'SET_TICKETS';
export const SET_TICKETS_SUCCESS = 'SET_TICKETS_SUCCESS';
export const SET_TICKETS_FAILURE = 'SET_TICKETS_FAILURE';

export const SET_CURRENT_TICKET = 'SET_CURRENT_TICKET';

export const ADD_TICKET = 'ADD_TICKET';
export const ADD_TICKET_SUCCESS = 'ADD_TICKET_SUCCESS';
export const ADD_TICKET_FAILURE = 'ADD_TICKET_FAILURE';

export const EDIT_TICKET = 'EDIT_TICKET';
export const EDIT_TICKET_SUCCESS = 'EDIT_TICKET_SUCCESS';
export const EDIT_TICKET_FAILURE = 'EDIT_TICKET_FAILURE';

export const DELETE_TICKET = 'DELETE_TICKET';
export const DELETE_TICKET_SUCCESS = 'DELETE_TICKET_SUCCESS';
export const DELETE_TICKET_FAILURE = 'DELETE_TICKET_FAILURE';

export const SAVE_TICKET_ORDER = 'SAVE_TICKET_ORDER';
export const SAVE_TICKET_ORDER_SUCCESS = 'SAVE_TICKET_ORDER_SUCCESS';
export const SAVE_TICKET_ORDER_FAILURE = 'SAVE_TICKET_ORDER_FAILURE';

// Goal actions
export const SET_GOALS = 'SET_GOALS';
export const SET_GOALS_SUCCESS = 'SET_GOALS_SUCCESS';
export const SET_GOALS_FAILURE = 'SET_GOALS_FAILURE';

export const SET_CURRENT_GOAL = 'SET_CURRENT_GOAL';

export const ADD_GOAL = 'ADD_GOAL';
export const ADD_GOAL_SUCCESS = 'ADD_GOAL_SUCCESS';
export const ADD_GOAL_FAILURE = 'ADD_GOAL_FAILURE';

export const EDIT_GOAL = 'EDIT_GOAL';
export const EDIT_GOAL_SUCCESS = 'EDIT_GOAL_SUCCESS';
export const EDIT_GOAL_FAILURE = 'EDIT_GOAL_FAILURE';

export const DELETE_GOAL = 'DELETE_GOAL';
export const DELETE_GOAL_SUCCESS = 'DELETE_GOAL_SUCCESS';
export const DELETE_GOAL_FAILURE = 'DELETE_GOAL_FAILURE';

export const SAVE_GOAL_ORDER = 'SAVE_GOAL_ORDER';
export const SAVE_GOAL_ORDER_SUCCESS = 'SAVE_GOAL_ORDER_SUCCESS';
export const SAVE_GOAL_ORDER_FAILURE = 'SAVE_GOAL_ORDER_FAILURE';

// News actions
export const SET_CURRENT_NEWS = 'SET_CURRENT_NEWS';

export const SET_NEWSES = 'SET_NEWSES';
export const SET_NEWSES_SUCCESS = 'SET_NEWSES_SUCCESS';
export const SET_NEWSES_FAILURE = 'SET_NEWSES_FAILURE';

export const ADD_NEWS = 'ADD_NEWS';
export const ADD_NEWS_SUCCESS = 'ADD_NEWS_SUCCESS';
export const ADD_NEWS_FAILURE = 'ADD_NEWS_FAILURE';

export const EDIT_NEWS = 'EDIT_NEWS';
export const EDIT_NEWS_SUCCESS = 'EDIT_NEWS_SUCCESS';
export const EDIT_NEWS_FAILURE = 'EDIT_NEWS_FAILURE';

export const DELETE_NEWS = 'DELETE_NEWS';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAILURE = 'DELETE_NEWS_FAILURE';

export const SAVE_NEWS_ORDER = 'SAVE_NEWS_ORDER';
export const SAVE_NEWS_ORDER_SUCCESS = 'SAVE_NEWS_ORDER_SUCCESS';
export const SAVE_NEWS_ORDER_FAILURE = 'SAVE_NEWS_ORDER_FAILURE';

// Audition actions
export const SET_CURRENT_AUDITION = 'SET_CURRENT_AUDITION';

export const SET_AUDITIONS = 'SET_AUDITIONS';
export const SET_AUDITIONS_SUCCESS = 'SET_AUDITIONS_SUCCESS';
export const SET_AUDITIONS_FAILURE = 'SET_AUDITIONS_FAILURE';

export const ADD_AUDITION = 'ADD_AUDITION';
export const ADD_AUDITION_SUCCESS = 'ADD_AUDITION_SUCCESS';
export const ADD_AUDITION_FAILURE = 'ADD_AUDITION_FAILURE';

export const EDIT_AUDITION = 'EDIT_AUDITION';
export const EDIT_AUDITION_SUCCESS = 'EDIT_AUDITION_SUCCESS';
export const EDIT_AUDITION_FAILURE = 'EDIT_AUDITION_FAILURE';

export const DELETE_AUDITION = 'DELETE_AUDITION';
export const DELETE_AUDITION_SUCCESS = 'DELETE_AUDITION_SUCCESS';
export const DELETE_AUDITION_FAILURE = 'DELETE_AUDITION_FAILURE';

export const SAVE_AUDITION_ORDER = 'SAVE_AUDITION_ORDER';
export const SAVE_AUDITION_ORDER_SUCCESS = 'SAVE_AUDITION_ORDER_SUCCESS';
export const SAVE_AUDITION_ORDER_FAILURE = 'SAVE_AUDITION_ORDER_FAILURE';

export const SET_HIDE_ROLES = 'SET_HIDE_ROLES';

export const SAVE_AUDITION_ROLE_ORDER = 'SAVE_AUDITION_ROLE_ORDER';
export const SAVE_AUDITION_ROLE_ORDER_SUCCESS = 'SAVE_AUDITION_ROLE_ORDER_SUCCESS';
export const SAVE_AUDITION_ROLE_ORDER_FAILURE = 'SAVE_AUDITION_ROLE_ORDER_FAILURE';

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../config';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  deleteGoalAction,
  deleteGoalSuccessAction,
  deleteGoalFailureAction,
  setCurrentGoalAction,
} from '../../Redux/Actions/goals';

function GoalCard(props) {
  const {
    index,
    classes,
    goal,
    loading,
    error,
    accessToken,
    deleteGoalAction,
    deleteGoalSuccessAction,
    deleteGoalFailureAction,
    setCurrentGoalAction,
    setScreenAction,
  } = props;

  const deleteGoal = (id) => {
    deleteGoalAction();

    fetch(`${config.api}/api/goal/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            deleteGoalFailureAction(result.error);
            return;
          }
          deleteGoalSuccessAction(goal);
        },
        (error) => {
          deleteGoalFailureAction(error);
        }
      );
  }

  const editGoal = (uuid) => {
    setCurrentGoalAction(uuid);
    setScreenAction('new_goal');
  }

  if (!goal) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={() => editGoal(goal._id)} disabled={loading}>
            Edit
                    </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => deleteGoal(goal._id)} color='secondary' startIcon={<DeleteIcon />} disabled={loading}>
            Delete
                    </Button>
          {loading ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>{goal.name}</Typography>
      </Grid>
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },

  editButton: {
    marginRight: 5
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    goal: state.goals.byIds[props.id],
    loading: state.goals.deleting,
    error: state.goals.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteGoalAction,
  deleteGoalSuccessAction,
  deleteGoalFailureAction,
  setCurrentGoalAction,
})(withStyles(style)(GoalCard));

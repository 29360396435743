import { process } from "../../helper";
import {
	ADD_AUDITION,
	ADD_AUDITION_FAILURE,
	ADD_AUDITION_SUCCESS,
	DELETE_AUDITION,
	DELETE_AUDITION_FAILURE,
	DELETE_AUDITION_SUCCESS,
	SAVE_AUDITION_ORDER,
	SAVE_AUDITION_ORDER_FAILURE,
	SAVE_AUDITION_ORDER_SUCCESS,
	SET_AUDITIONS,
	SET_AUDITIONS_FAILURE,
	SET_AUDITIONS_SUCCESS,
	SET_CURRENT_AUDITION,
	SET_HIDE_ROLES,
	SAVE_AUDITION_ROLE_ORDER,
	SAVE_AUDITION_ROLE_ORDER_SUCCESS,
	SAVE_AUDITION_ROLE_ORDER_FAILURE,
	EDIT_AUDITION,
	EDIT_AUDITION_SUCCESS,
	EDIT_AUDITION_FAILURE,
} from "../actionTypes";

const initialState = {
	allIds: [],
	byIds: {},
	current: null,
	fetched: false,
	loading: false,
	deleting: false,
	error: null,
}

export default function auditionsReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AUDITIONS: {

			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case SET_AUDITIONS_SUCCESS: {
			const { auditions } = action.payload;
			const { allIds, byIds } = process(auditions);

			return {
				...state,
				allIds,
				byIds,
				loading: false,
				fetched: true,
				error: null,
			};
		}

		case SET_AUDITIONS_FAILURE: {
			const { error } = action.payload;

			return {
				...state,
				loading: false,
				error,
			};
		}

		case SET_CURRENT_AUDITION: {
			const { uuid } = action.payload;

			return {
				...state,
				current: uuid,
			};
		}

		case ADD_AUDITION: {
			return {
				...state,
				loading: true,
				error: null,
			};
		}

		case ADD_AUDITION_SUCCESS: {
			const { audition } = action.payload;
			const allIds = [...state.allIds, audition._id];
			const byIds = { ...state.byIds, [audition._id]: audition };

			return {
				...state,
				allIds,
				byIds,
				loading: false,
				error: null,
			};
		}

		case ADD_AUDITION_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
		}

		case SAVE_AUDITION_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      };
		}

		case SAVE_AUDITION_ORDER_SUCCESS: {
      const { ids } = action.payload;

			return {
        ...state,
        allIds: [...ids],
        loading: false,
      };
		}

		case SAVE_AUDITION_ORDER_FAILURE:{
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
		}

		case DELETE_AUDITION: {

			return {
				...state,
				deleting: true,
				error: null,
			};
		}

		case DELETE_AUDITION_SUCCESS: {
			const { audition } = action.payload;
			const allIds = state.allIds.filter(id => id !== audition._id);
			const { [audition._id]: deleted, ...byIds } = state.byIds;

			return {
				...state,
				allIds,
				byIds,
				deleting: false,
				error: null,
			};
		}

		case DELETE_AUDITION_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
		}

		case SET_HIDE_ROLES: {
			const { auditionId, hideRoles } = action.payload;

			return {
				...state,
				byIds: {
					...state.byIds,
					[auditionId]: {
						...state.byIds[auditionId],
						hideRoles
					}
				}
			}
		}

		case SAVE_AUDITION_ROLE_ORDER: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}

		case SAVE_AUDITION_ROLE_ORDER_SUCCESS: {
			const { auditionId, rolEs } = action.payload;

			return {
				...state,
				byIds: {
					...state.byIds,
					[auditionId]: {
						...state.byIds[auditionId],
						rolEs
					}
				},
				loading: false,
			}
		}

		case SAVE_AUDITION_ROLE_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

		case EDIT_AUDITION: {
			return {
				...state,
				loading: true,
				error: null,
			}
		}

		case EDIT_AUDITION_SUCCESS: {
			const { audition } = action.payload;

			return {
				...state,
				byIds: {
					...state.byIds,
					[audition._id]: audition,
				},
				loading: false,
				error:null,
			}
		}

		case EDIT_AUDITION_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

		default:
			return state;
	}
}
import React from 'react';
import { connect } from 'react-redux';
import { Paper, Avatar, Typography, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../config';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setCurrentUpcomingAction,
  deleteUpcomingAction,
  deleteUpcomingSuccessAction,
  deleteUpcomingFailureAction,
} from '../../Redux/Actions/upcomings';

function UpcomingCard(props) {
  const {
    index,
    classes,
    upcoming,
    loading,
    error,
    accessToken,
    deleteUpcomingAction,
    deleteUpcomingSuccessAction,
    deleteUpcomingFailureAction,
    setCurrentUpcomingAction,
    setScreenAction,
  } = props;

  const deleteUpcoming = (id) => {
    deleteUpcomingAction();

    fetch(`${config.api}/api/upcoming/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            deleteUpcomingFailureAction(result.error);
            return;
          }
          deleteUpcomingSuccessAction(upcoming);
        },
        (error) => {
          deleteUpcomingFailureAction(error);
        }
      );
  }

  const editUpcoming = (uuid) => {
    setCurrentUpcomingAction(uuid);
    setScreenAction('new_upcoming');
  }

  if (!upcoming) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={() => editUpcoming(upcoming._id)} disabled={loading}>
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => deleteUpcoming(upcoming._id)} color='secondary' startIcon={<DeleteIcon />} disabled={loading}>
            Delete
                    </Button>
          {loading ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar alt={upcoming.name} src={`${upcoming.poster}?${new Date().getTime()}`} className={classes.large} />
        <Typography>{upcoming.name}</Typography>
      </Grid>
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },

  editButton: {
    marginRight: 5
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    upcoming: state.upcomings.byIds[props.id],
    loading: state.upcomings.deleting,
    error: state.upcomings.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteUpcomingAction,
  deleteUpcomingSuccessAction,
  deleteUpcomingFailureAction,
  setCurrentUpcomingAction,
})(withStyles(style)(UpcomingCard));

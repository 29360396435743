import { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Card, Container, Grid, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ImageUploader from '../../Component/ImageUploader';
import SaveIcon from '@material-ui/icons/Save';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import {
    addAuditionAction,
    addAuditionFailureAction,
    addAuditionSuccessAction,
    editAuditionAction,
    editAuditionFailureAction,
    editAuditionSuccessAction,
} from '../../Redux/Actions/auditions';
import { setScreenAction } from '../../Redux/Actions/app';

function NewAudition(props) {
    const {
        audition,
        loading,
        accessToken,
        next,
        classes,
        error,
        addAuditionAction,
        addAuditionFailureAction,
        addAuditionSuccessAction,
        editAuditionAction,
        editAuditionFailureAction,
        editAuditionSuccessAction,
        setScreenAction,
    } = props;

    const [title, setTitle] = useState(audition ? audition.title : '');
    const [description, setDescription] = useState(audition ? audition.description : '');
    const [image, setImage] = useState(audition ? audition.image.replace(`${config.api}/`, '') : '');
    const [roles, setRoles] = useState(audition ? audition.roles : []);
    const [order, setOrder] = useState(audition ? audition.order : next);

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setImage(uploadPath);
        }
    }

    const onSave = () => {
        const audition = {
            title,
            description,
            image,
            roles,
            order,
        };

        addAuditionAction();

        fetch(`${config.api}/api/auditions`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(audition)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.error) {
                        addAuditionFailureAction(result.error);
                        return;
                    }

                    setTitle('');
                    setDescription('');
                    setImage('');
                    setRoles([]);
                    setOrder(order + 1);

                    audition._id = result.data._id;
                    audition.image = result.data.image;

                    addAuditionSuccessAction(audition);
                },
                error => {
                    addAuditionFailureAction(error);
                }
            )
    }

    const onUpdate = () => {
        const payload = {
            _id: audition._id,
            title,
            description,
            image,
            roles,
            order,
        };

        editAuditionAction();

        fetch(`${config.api}/api/audition/${audition._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ audition: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editAuditionFailureAction(error);
                        return;
                    }

                    payload.image = result.image;

                    editAuditionSuccessAction(payload);
                    setScreenAction('audition');
                },
                (error) => {
                    // connection errors
                    editAuditionFailureAction(error);
                }
            );
    }

    const addRole = () => {
        setRoles(roles => [...roles, { name: '', description: '' }]);
    }

    const deleteRole = index => {
        setRoles(roles => {
            const updated = [...roles];

            updated.splice(index, 1);

            return updated;
        });
    }

    const onNameChange = (index, name) => {
        setRoles(roles => {
            const updated = [...roles];

            updated[index] = {
                name,
                description: updated[index].description,
            }

            return updated;
        });
    }

    const onDescriptionChange = (index, description) => {
        setRoles(roles => {
            const updated = [...roles];

            updated[index] = {
                name: updated[index].name,
                description,
            }

            return updated;
        })
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                >
                    <Grid item>
                        <ImageUploader
                            value={image && `${config.api}/${image}?${new Date().getTime()}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.textField}
                            variant='outlined'
                            label='Title'
                            value={title}
                            onChange={event => setTitle(event.target.value)}
                            disabled={loading}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            className={classes.textField}
                            variant='outlined'
                            label='Description'
                            value={description}
                            onChange={event => setDescription(event.target.value)}
                            disabled={loading}
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    justifyContent='flex-end'
                >
                    <Grid item>
                        <Button variant="contained" color='primary' onClick={addRole} className={classes.textField} >Add Role</Button>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction='column'
                    alignItems='center'
                >
                    {roles.map((role, index) => (
                        <Grid container key={index} justifyContent='space-around' className={classes.textField}>
                            <Grid item>
                                <TextField
                                    variant='outlined'
                                    label='Name'
                                    value={role.name}
                                    onChange={event => onNameChange(index, event.target.value)}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant='outlined'
                                    label='Description'
                                    value={role.description}
                                    onChange={event => onDescriptionChange(index, event.target.value)}
                                    disabled={loading}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color='secondary' onClick={() => deleteRole(index)}>X</Button>
                            </Grid>
                        </Grid>
                    ))}
                    {audition ? (
                        <Grid item>
                            {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} startIcon={<SaveIcon />} >Update</Button>}
                        </Grid>
                    ) : (
                        <Grid item>
                            {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                        </Grid>
                    )}
                    <Grid item>
                        {error && <Error message={'Problem Saving Member'} alignment={'center'} />}
                    </Grid>
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)',
        padding: 20,
    },
    textField: {
        marginTop: 20,
    },
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.accessToken,
        loading: state.auditions.loading,
        audition: state.auditions.byIds[state.auditions.current],
        next: state.auditions.allIds.length,
    };
};

export default connect(mapStateToProps, {
    addAuditionAction,
    addAuditionSuccessAction,
    addAuditionFailureAction,
    editAuditionAction,
    editAuditionFailureAction,
    editAuditionSuccessAction,
    setScreenAction,
})(withStyles(style)(NewAudition));

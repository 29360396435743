import {
    SET_SHOWS,
    SET_SHOWS_SUCCESS,
    SET_SHOWS_FAILURE,

    ADD_SHOW,
    ADD_SHOW_SUCCESS,
    ADD_SHOW_FAILURE,

    EDIT_SHOW,
    EDIT_SHOW_SUCCESS,
    EDIT_SHOW_FAILURE,

    DELETE_SHOW,
    DELETE_SHOW_SUCCESS,
    DELETE_SHOW_FAILURE,

    SET_CURRENT_SHOW,

    SAVE_SHOW_ORDER,
    SAVE_SHOW_ORDER_SUCCESS,
    SAVE_SHOW_ORDER_FAILURE,

    SET_HIDE_PRODUCTION_MEMBERS,
    SET_HIDE_CAST_MEMBERS,
    SET_HIDE_PHOTOS
} from '../actionTypes';

export const setShowsAction = () => ({
    type: SET_SHOWS,
})

export const setShowsSuccessAction = (seasonId, shows) => ({
    type: SET_SHOWS_SUCCESS,
    payload: {
        seasonId,
        shows
    }
})

export const setShowsFailureAction = error => ({
    type: SET_SHOWS_FAILURE,
    payload: {
        error
    }
})

export const deleteShowAction = () => ({
    type: DELETE_SHOW,
})

export const deleteShowSuccessAction = (show) => ({
    type: DELETE_SHOW_SUCCESS,
    payload: {
        show,
    }
})

export const deleteShowFailureAction = error => ({
    type: DELETE_SHOW_FAILURE,
    payload: {
        error
    }
})

export const addShowAction = () => ({
    type: ADD_SHOW,
})

export const addShowSuccessAction = (show) => ({
    type: ADD_SHOW_SUCCESS,
    payload: {
        show,
    }
})

export const addShowFailureAction = error => ({
    type: ADD_SHOW_FAILURE,
    payload: {
        error
    }
})

export const editShowAction = () => ({
    type: EDIT_SHOW,
})

export const editShowSuccessAction = (show) => ({
    type: EDIT_SHOW_SUCCESS,
    payload: {
        show,
    }
})

export const editShowFailureAction = error => ({
    type: EDIT_SHOW_FAILURE,
    payload: {
        error
    }
})

export const setCurrentShowAction = showId => ({
    type: SET_CURRENT_SHOW,
    payload: {
        showId
    }
})

export const setSaveShowOrderAction = () => ({
    type: SAVE_SHOW_ORDER
})

export const setSaveShowOrderSuccessAction = ids => ({
    type: SAVE_SHOW_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveShowOrderFailureAction = error => ({
    type: SAVE_SHOW_ORDER_FAILURE,
    payload: {
        error
    }
})

export const setHideProductionMembersAction = (hideProductionMembers, showId) => ({
    type: SET_HIDE_PRODUCTION_MEMBERS,
    payload: {
        hideProductionMembers,
        showId
    }
})

export const setHideCastMembersAction = (hideCastMembers, showId) => ({
    type: SET_HIDE_CAST_MEMBERS,
    payload: {
        hideCastMembers,
        showId
    }
})

export const setHidePhotosAction = (hidePhotos, showId) => ({
    type: SET_HIDE_PHOTOS,
    payload: {
        hidePhotos,
        showId
    }
})

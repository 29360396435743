import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, TextField, Grid, Card, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addPhotoAction,
    addPhotoSuccessAction,
    addPhotoFailureAction,
    editPhotoAction,
    editPhotoSuccessAction,
    editPhotoFailureAction,
} from '../../Redux/Actions/photoGallery';

function NewGalleryPhoto(props) {
    const {
        classes,
        photo,
        next,
        loading,
        error,
        accessToken,
        addPhotoAction,
        addPhotoSuccessAction,
        addPhotoFailureAction,
        editPhotoAction,
        editPhotoSuccessAction,
        editPhotoFailureAction,
        setScreenAction,
    } = props;

    const [image, setImage] = useState(photo ? photo.image.replace(`${config.api}/`, '') : null);
    const [description, setDescription] = useState(photo ? photo.description : '');
    const [year, setYear] = useState(photo ? photo.year : '');
    const [order, setOrder] = useState(photo ? photo.order : next);

    const onSave = () => {
        const payload = {
            image,
            description,
            year,
            order,
        };

        addPhotoAction();

        fetch(`${config.api}/api/photo-gallery`, {
            method: 'POST',
            headers: {
               'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addPhotoFailureAction(error);
                        return;
                    }

                    setImage(null)
                    setDescription('')
                    setYear('')
                    setOrder(order + 1);

                    payload._id = result.data._id;
                    payload.image = result.data.image;

                    addPhotoSuccessAction(payload);
                },
                    (error) => {
                        // connection errors
                        addPhotoFailureAction(error)
                    }
                );
    }

    const onUpdate = () => {
        const payload = {
            _id: photo._id,
            image,
            description,
            year,
            order,
        };

        editPhotoAction();

        fetch(`${config.api}/api/photo-gallery/${photo._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ photo: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editPhotoFailureAction(result.error);
                        return;
                    }

                    payload.image = result.image;

                    editPhotoSuccessAction(payload);
                    setScreenAction('photo_gallery')
                },
                (error) => {
                    editPhotoFailureAction(error)
                }
            );
    }

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setImage(uploadPath);
        }
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.textField}
                            value={image && `${config.api}/${image}?${new Date().getTime()}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Description" className={classes.textField} value={description} onChange={event => setDescription(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Year" className={classes.textField} value={year} onChange={event => setYear(event.target.value)} disabled={loading} />
                    </Grid>
                    {
                        photo === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving Photo'} alignment={'center'} /> : ''}
                           </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} startIcon={<SaveIcon />} >Update</Button>}
                                {error ? <Error message={'Problem Saving Photo'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
      accessToken: state.auth.accessToken,
      photo: state.photoGallery.byIds[state.photoGallery.current],
      next: state.photoGallery.allIds.length,
      loading: state.photoGallery.loading,
      error: state.photoGallery.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addPhotoAction,
    addPhotoSuccessAction,
    addPhotoFailureAction,
    editPhotoAction,
    editPhotoSuccessAction,
    editPhotoFailureAction,
})(withStyles(style)(NewGalleryPhoto));

import {
    SET_UPCOMINGS,
    SET_UPCOMINGS_SUCCESS,
    SET_UPCOMINGS_FAILURE,

    ADD_UPCOMING,
    ADD_UPCOMING_SUCCESS,
    ADD_UPCOMING_FAILURE,

    EDIT_UPCOMING,
    EDIT_UPCOMING_SUCCESS,
    EDIT_UPCOMING_FAILURE,

    DELETE_UPCOMING,
    DELETE_UPCOMING_SUCCESS,
    DELETE_UPCOMING_FAILURE,

    SET_CURRENT_UPCOMING,

    SAVE_UPCOMING_ORDER,
    SAVE_UPCOMING_ORDER_SUCCESS,
    SAVE_UPCOMING_ORDER_FAILURE,
} from '../actionTypes';

export const setUpcomingsAction = () => ({
    type: SET_UPCOMINGS,
})

export const setUpcomingsSuccessAction = upcomings => ({
    type: SET_UPCOMINGS_SUCCESS,
    payload: {
        upcomings
    }
})

export const setUpcomingsFailureAction = error => ({
    type: SET_UPCOMINGS_FAILURE,
    payload: {
        error
    }
})

export const deleteUpcomingAction = () => ({
    type: DELETE_UPCOMING,
})

export const deleteUpcomingSuccessAction = upcoming => ({
    type: DELETE_UPCOMING_SUCCESS,
    payload: {
        upcoming
    }
})

export const deleteUpcomingFailureAction = error => ({
    type: DELETE_UPCOMING_FAILURE,
    payload: {
        error
    }
})

export const addUpcomingAction = () => ({
    type: ADD_UPCOMING,
})

export const addUpcomingSuccessAction = upcoming => ({
    type: ADD_UPCOMING_SUCCESS,
    payload: {
        upcoming
    }
})

export const addUpcomingFailureAction = error => ({
    type: ADD_UPCOMING_FAILURE,
    payload: {
        error
    }
})

export const editUpcomingAction = () => ({
    type: EDIT_UPCOMING,
})

export const editUpcomingSuccessAction = upcoming => ({
    type: EDIT_UPCOMING_SUCCESS,
    payload: {
        upcoming
    }
})

export const editUpcomingFailureAction = error => ({
    type: EDIT_UPCOMING_FAILURE,
    payload: {
        error
    }
})

export const setCurrentUpcomingAction = uuid => ({
    type: SET_CURRENT_UPCOMING,
    payload: {
        uuid
    }
})

export const setSaveUpcomingOrderAction = () => ({
    type: SAVE_UPCOMING_ORDER
})

export const setSaveUpcomingOrderSuccessAction = ids => ({
    type: SAVE_UPCOMING_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveUpcomingOrderFailureAction = error => ({
    type: SAVE_UPCOMING_ORDER_FAILURE,
    payload: {
        error
    }
})

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import ShowCard from '../../Component/ShowCard';
import config from '../../config';
import Error from '../../Component/Error';
import Loader from '../../Component/Loader';
import { Container, Grid, Button } from '@material-ui/core';
import { setScreenAction } from '../../Redux/Actions/app';
import { setCurrentSeasonAction } from '../../Redux/Actions/seasons';
import {
  setShowsAction,
  setShowsSuccessAction,
  setShowsFailureAction,
  setCurrentShowAction,
  setSaveShowOrderAction,
  setSaveShowOrderSuccessAction,
  setSaveShowOrderFailureAction,
} from '../../Redux/Actions/shows';
import DragAndDrop from '../DragAndDrop';

function Shows(props) {
  const {
    accessToken,
    seasonId,
    fetched,
    shows,
    loading,
    error,
    setHideShows,
    setScreenAction,
    setCurrentSeasonAction,
    setShowsAction,
    setShowsSuccessAction,
    setShowsFailureAction,
    setCurrentShowAction,
    setSaveShowOrderAction,
    setSaveShowOrderSuccessAction,
    setSaveShowOrderFailureAction,
  } = props;

  const [localShows, setLocalShows] = useState(shows);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalShows(shows);
  }, [shows]);

  useEffect(() => {
    if (!fetched) {
      setShowsAction(seasonId);
      fetch(`${config.api}/api/season/${seasonId}/shows`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setShowsSuccessAction(seasonId, result.data)
          },
          (error) => {
            setShowsFailureAction(error);
          }
        );
    }
  }, [fetched, seasonId, setShowsAction, setShowsSuccessAction, setShowsFailureAction]);

  const newShow = () => {
    setCurrentSeasonAction(seasonId);
    setCurrentShowAction(null);
    setScreenAction('new_show');
  }

  const saveOrder = () => {
    setSaveShowOrderAction();

    fetch(`${config.api}/api/shows/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        showIds: localShows,
        seasonUuid: seasonId,
      }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            setSaveShowOrderFailureAction(result.error);
            return;
          }

          setSaveShowOrderSuccessAction(localShows);
          setShowOrderSaveButton(false);
        },
        (error) => {
          // connection errors
          setSaveShowOrderFailureAction(error);
        }
      );
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={() => setHideShows(true)} color='primary'>Hide Shows</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={newShow}>New Show</Button>
        </Grid>
      </Grid>
    );
  };

  if (loading || !fetched) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localShows}
        setLocal={setLocalShows}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='show'
        Card={ShowCard}
      />
      {error ? <Error message='Could not get shows' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.shows.fetched[props.seasonId] || false,
    shows: state.seasons.byIds[props.seasonId].shows,
    loading: state.shows.loading,
    error: state.shows.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setCurrentSeasonAction,
  setShowsAction,
  setShowsSuccessAction,
  setShowsFailureAction,
  setCurrentShowAction,
  setSaveShowOrderAction,
  setSaveShowOrderSuccessAction,
  setSaveShowOrderFailureAction,
})(Shows);

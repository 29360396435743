import React from 'react';
import { connect } from 'react-redux';
import { Paper, Avatar, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../config';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    deletePhotoAction,
    deletePhotoSuccessAction,
    deletePhotoFailureAction,
    setCurrentPhotoAction,
} from '../../Redux/Actions/photoGallery';

function GalleryPhotoCard(props) {
    const {
        index,
        classes,
        photo,
        deleting,
        error,
        accessToken,
        deletePhotoAction,
        deletePhotoSuccessAction,
        deletePhotoFailureAction,
        setCurrentPhotoAction,
        setScreenAction
    } = props;

    const deletePhoto = (id) => {
        deletePhotoAction();

        fetch(`${config.api}/api/photo-gallery/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    deletePhotoSuccessAction(photo);
                },
                (error) => {
                    deletePhotoFailureAction(error)
                }
            );
    }

    const editPhoto = (uuid) => {
        setCurrentPhotoAction(uuid);
        setScreenAction('new_gallery_photo')
    }

    if (!photo) {
        return null;
    }

    return (
        <Paper
            className={classes.root}
        >
            <div>{index + 1}</div>
            <Grid container justifyContent="flex-end" spacing={4}>
                <Grid item>
                    <Button variant="contained" onClick={() => editPhoto(photo._id)} disabled={deleting}>
                        Edit
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={() => deletePhoto(photo._id)} color='secondary' startIcon={<DeleteIcon />} disabled={deleting}>
                        Delete
                    </Button>
                    {deleting ? <Loader size={36} /> : ''}
                    {error ? <Error message={'Problem Deleting'} alignment={'flex-end'}/> : ''}
                </Grid>
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <Avatar alt={`image${photo.order}`} src={`${photo.image}?${new Date().getTime()}`} className={classes.large} />
            </Grid>
        </Paper>
    );
}

const style = (theme) => ({
    root: {
        marginTop: 25,
        padding: 20,
        background: 'rgb(230, 230, 230)'
    },

    editButton: {
        marginRight: 5
    },

    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
});

const mapStateToProps = (state, props) => {
    return {
      accessToken: state.auth.accessToken,
      photo: state.photoGallery.byIds[props.id],
      deleting: state.photoGallery.deleting,
      error: state.photoGallery.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    deletePhotoAction,
    deletePhotoSuccessAction,
    deletePhotoFailureAction,
    setCurrentPhotoAction
})(withStyles(style)(GalleryPhotoCard));

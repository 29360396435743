import React from 'react';
import { connect } from 'react-redux';
import { Container, withStyles } from '@material-ui/core';
import Navbar from '../../Component/NavBar';
import Members from '../Members';
import NewMember from '../New Member';
import Seasons from '../Seasons';
import NewSeason from '../New Season';
import NewShow from '../New Show';
import NewProductionMember from '../New Production Member';
import NewCastMember from '../New Cast Member';
import NewPhoto from '../New Photo';
import PhotoGallery from '../Photo Gallery';
import Tickets from '../Tickets';
import NewTicket from '../New Ticket';
import Goals from '../Goals';
import NewGoal from '../New Goal';
import NewGalleryPhoto from '../New Gallery Photo';
import EmptyState from '../EmptyState';
import Upcoming from '../Upcoming';
import NewUpcoming from '../New Upcoming';
import Newses from '../Newses';
import NewNews from '../New News';
import Login from '../Login';
import Audition from '../Audition';
import config from '../../config';
import NewAudition from '../New Audition';

function App(props) {
  const { loggedIn, screen, state, classes } = props;

  if (config.debug) {
    console.log('State: ', state)
  }

  const renderBody = () => {
    if (!loggedIn) {
      return (
        <Login />
      );
    }

    switch (screen) {
      case 'member':
        return <Members />;
      case 'new_member':
        return <NewMember />;
      case 'season':
        return <Seasons />;
      case 'new_season':
        return <NewSeason />;
      case 'new_show':
        return <NewShow />;
      case 'new_production_member':
        return <NewProductionMember />;
      case 'new_cast_member':
        return <NewCastMember />;
      case 'new_photo':
        return <NewPhoto />;
      case 'photo_gallery':
        return <PhotoGallery />;
      case 'new_gallery_photo':
        return <NewGalleryPhoto />;
      case 'upcoming':
        return <Upcoming />;
      case 'new_upcoming':
        return <NewUpcoming />;
      case 'ticket':
        return <Tickets />;
      case 'new_ticket':
        return <NewTicket />;
      case 'goals':
        return <Goals />;
      case 'new_goal':
        return <NewGoal />;
      case 'edit_login':
        return <Login />;
      case 'newses':
        return <Newses />;
      case 'new_news':
        return <NewNews />;
      case 'audition':
        return <Audition />;
      case 'new_audition':
        return <NewAudition />;
      default:
        return <EmptyState />;
    }
  }

  return (
    <Container>
      <Navbar />
      <Container className={classes.content}>
        {renderBody()}
      </Container>
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    loggedIn: state.auth.loggedIn,
    screen: state.app.screen,
    state,
  };
};

export default connect(mapStateToProps)(withStyles({ content: { marginTop: 100 } })(App));

import React, { useState } from 'react';
import config from '../../config';
import { withStyles } from '@material-ui/core/styles';
import { Avatar, Grid } from '@material-ui/core';
import Error from '../Error';

function ImageUploader(props) {
    const { classes, onChange, value, loading, accessToken } = props;
    const [error, setError] = useState(null);

    const handleChange = (event) => {
        if (event.target.files[0]) {
            const body = new FormData();
            body.append('file', event.target.files[0]);

            fetch(`${config.api}/api/upload`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'x-access-token': accessToken,
                },
                body,
            })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (result.error) {
                            // result error
                            setError(result.error);
                            return;
                        }
                        onChange(result.data.filename);
                    },
                    (error) => {
                        // connection errors
                        setError(error);
                    }
                );
        }
    }

    return (
        <Grid container direction='column' justifyContent='center' alignContent='center' className={classes.root}>
            <Grid item>
                <Avatar src={value} className={classes.large} />
                <input
                    id='image'
                    className={classes.input}
                    accept='image/*'
                    type='file'
                    onChange={handleChange}
                    disabled={loading}
                />
            </Grid>
            <Grid item>
                {error ? <Error message={'Problem Uploading image'} alignment={'center'} /> : ''}
            </Grid>
        </Grid >
    )
}

const style = (theme) => ({
    root: {
        marginTop: 25,
        position: 'relative',
    },
    input: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
    },
    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        margin: 'auto'
    },
    error: {
        color: 'salmon',
    },
});

export default withStyles(style)(ImageUploader);

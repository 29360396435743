import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, Grid, Card, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addShowAction,
    addShowSuccessAction,
    addShowFailureAction,
    editShowAction,
    editShowSuccessAction,
    editShowFailureAction,
} from '../../Redux/Actions/shows';

function NewShow(props) {
    const {
        classes,
        seasonId,
        show,
        next,
        loading,
        error,
        accessToken,
        setScreenAction,
        addShowAction,
        addShowSuccessAction,
        addShowFailureAction,
        editShowAction,
        editShowSuccessAction,
        editShowFailureAction,
    } = props;

    const [poster, setPoster] = useState(show ? show.poster.replace(`${config.api}/`, '') : null);
    const [name, setName] = useState(show ? show.name : '');
    const [description, setDescription] = useState(show ? show.description : '');
    const [order, setOrder] = useState(show ? show.order : next);

    const onSave = () => {
        const payload = {
            poster,
            name,
            description,
            season: seasonId,
            order,
        };

        addShowAction();

        fetch(`${config.api}/api/show`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addShowFailureAction(error);
                        return;
                    }
                    setPoster(null);
                    setName('');
                    setDescription('');
                    setOrder(order + 1);

                    payload._id = result.data._id;
                    payload.poster = result.data.poster;
                    payload.productionList = [];
                    payload.castList = [];
                    payload.photoGallery = [];

                    addShowSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addShowFailureAction(error);
                }
            );
    }


    const onUpdate = () => {
        const payload = {
            _id: show._id,
            season: seasonId,
            poster,
            name,
            description,
            productionList: show.productionList,
            castList: show.castList,
            photoGallery: show.photoGallery,
            order,
        };

        editShowAction();

        fetch(`${config.api}/api/show/${show._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ show: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editShowFailureAction(result.error);
                        return;
                    }

                    payload.poster = result.poster;

                    editShowSuccessAction(payload);
                    setScreenAction('season');
                },
                (error) => {
                    editShowFailureAction(error);
                }
            );
    }

    const uploadPoster = (uploadPath) => {
        if (uploadPath) {
            setPoster(uploadPath);
        }
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.textField}
                            value={poster && `${config.api}/${poster}`}
                            onChange={uploadPoster}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" id="name" label="Name" className={classes.textField} value={name} onChange={event => setName(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <ReactQuill className={classes.textField} value={description} onChange={setDescription} disabled={loading} />
                    </Grid>
                    {
                        show === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving show'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} startIcon={<SaveIcon />} >Update</Button>}
                                {error ? <Error message={'Problem Saving show'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.accessToken,
        seasonId: state.seasons.current,
        show: state.shows.byIds[state.shows.current],
        next: state.shows.allIds.length,
        loading: state.shows.loading,
        error: state.shows.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addShowAction,
    addShowSuccessAction,
    addShowFailureAction,
    editShowAction,
    editShowSuccessAction,
    editShowFailureAction,
})(withStyles(style)(NewShow));

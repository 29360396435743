import {
  SET_CURRENT_TICKET,

  SET_TICKETS,
  SET_TICKETS_SUCCESS,
  SET_TICKETS_FAILURE,

  ADD_TICKET,
  ADD_TICKET_SUCCESS,
  ADD_TICKET_FAILURE,

  EDIT_TICKET,
  EDIT_TICKET_SUCCESS,
  EDIT_TICKET_FAILURE,

  DELETE_TICKET,
  DELETE_TICKET_SUCCESS,
  DELETE_TICKET_FAILURE,

  SAVE_TICKET_ORDER,
  SAVE_TICKET_ORDER_SUCCESS,
  SAVE_TICKET_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
    allIds: [],
    byIds: {},
    current: null,
    fetched: false,
    loading: false,
    deleting: false,
    error: null,
};

export default function ticketsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TICKETS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_TICKETS_SUCCESS: {
      const { tickets } = action.payload;
      const { allIds, byIds } = process(tickets);

      return {
        ...state,
        allIds,
        byIds,
        loading: false,
        fetched: true,
        error: null,
      };
    }

    case SET_TICKETS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case SET_CURRENT_TICKET: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case ADD_TICKET: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_TICKET_SUCCESS: {
      const { ticket } = action.payload;
      const newAllIds = [...state.allIds, ticket._id];
      const newByIds = { [ticket._id]: ticket, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_TICKET_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_TICKET: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_TICKET_SUCCESS: {
      const { ticket } = action.payload;
      const newByIds = { ...state.byIds, [ticket._id]: ticket };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_TICKET_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_TICKET: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_TICKET_SUCCESS: {
      const { ticket } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== ticket._id);
      const { [ticket._id]: deletedTicket, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_TICKET_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SAVE_TICKET_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_TICKET_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_TICKET_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setScreenAction } from '../../Redux/Actions/app';

function Navbar(props) {
    const {
        classes,
        setScreenAction,
        loggedIn,
        currentScreen,
    } = props;

    if (!loggedIn) {
        return (
            <AppBar>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        Admin
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }

    const pages = [
        {
            label: 'Upcoming',
            screen: 'upcoming',
        },
        {
            label: 'Audition',
            screen: 'audition',
        },
        {
            label: 'Members',
            screen: 'member',
        },
        {
            label: 'Seasons',
            screen: 'season',
        },
        {
            label: 'Photo Gallery',
            screen: 'photo_gallery',
        },
        {
            label: 'Tickets',
            screen: 'ticket',
        },
        {
            label: 'Goals',
            screen: 'goals',
        },
        {
            label: 'News',
            screen: 'newses',
        },
    ]

    return (
        <AppBar>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                    <Button onClick={() => setScreenAction('edit_login')} className={ currentScreen === 'edit_login' ? classes.active : classes.linkText}>
                        Admin
                    </Button>
                </Typography>
                {pages.map((page, index) => (
                    <Button key={index} onClick={() => setScreenAction(page.screen)} className={ currentScreen === page.screen ? classes.active : classes.linkText}>
                        {page.label}
                    </Button>
                ))}
            </Toolbar>
        </AppBar>
    );
}

const styles = {
    title: {
        flexGrow: 1,
    },
    linkText: {
        color: 'white',
    },
    active: {
        color: 'aqua',
    },
}

const mapStateToProps = state => {
    return {
        loggedIn: state.auth.loggedIn,
        currentScreen: state.app.screen,
    };
};

export default connect(mapStateToProps, { setScreenAction })(withStyles(styles)(Navbar));

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Avatar, Grid, Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ProductionMembers from '../../Container/Production Members';
import CastMembers from '../../Container/Cast Members';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../Loader';
import Error from '../Error';
import config from '../../config';
import Photos from '../../Container/Photos';
import {
  addDisabledDND,
  removeDisabledDND,
  setScreenAction
} from '../../Redux/Actions/app';
import {
  deleteShowAction,
  deleteShowSuccessAction,
  deleteShowFailureAction,
  setCurrentShowAction,
  setHideProductionMembersAction,
  setHideCastMembersAction,
  setHidePhotosAction,
} from '../../Redux/Actions/shows';

function ShowCard(props) {
  const {
    index,
    id,
    classes,
    show,
    deleting,
    error,
    accessToken,
    deleteShowAction,
    deleteShowSuccessAction,
    deleteShowFailureAction,
    setCurrentShowAction,
    setScreenAction,
    addDisabledDND,
    removeDisabledDND,
    hideProductionMembers,
    hideCastMembers,
    hidePhotos,
    setHideProductionMembersAction,
    setHideCastMembersAction,
    setHidePhotosAction,
  } = props;

  const toggleProductionMembers = value => {
    if (value) {
      removeDisabledDND('show');
    } else {
      addDisabledDND('show');
    }

    setHideProductionMembersAction(value, id);
  }

  const toggleCastMembers = value => {
    if (value) {
      removeDisabledDND('show');
    } else {
      addDisabledDND('show');
    }

    setHideCastMembersAction(value, id);
  }
  const togglePhotos = value => {
    if (value) {
      removeDisabledDND('show');
    } else {
      addDisabledDND('show');
    }

    setHidePhotosAction(value, id);
  }

  const deleteShow = () => {
    deleteShowAction();

    fetch(`${config.api}/api/show/${show._id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          deleteShowSuccessAction(show);
        },
        (error) => {
          deleteShowFailureAction(error)
        }
      );
  }

  const editShow = () => {
    setCurrentShowAction(show._id);
    setScreenAction('new_show');
  }

  if (!show) {
    return null;
  }

  const disableDelete = !(show.productionList.length === 0 && show.castList.length === 0 && show.photoGallery.length === 0);

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={editShow} disabled={deleting}>
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={deleteShow} color='secondary' startIcon={<DeleteIcon />} disabled={deleting || disableDelete}>
            Delete
                    </Button>
          {deleting ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
        <Grid item xs={12}>
          <Avatar alt={show.name} src={`${show.poster}?${new Date().getTime()}`} className={classes.avatar} />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.name}>{show.name}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.buttonRow}>
          {hideProductionMembers ? <Button variant="contained" onClick={() => toggleProductionMembers(false)} color='primary'>Show Production Members</Button> :
            <ProductionMembers
              showId={show._id}
              setHideProductionMembers={toggleProductionMembers}
            />
          }
        </Grid>
        <Grid item xs={12} className={classes.buttonRow}>
          {hideCastMembers ? <Button variant="contained" onClick={() => toggleCastMembers(false)} color='primary'>Show Cast Members</Button> :
            <CastMembers
              showId={show._id}
              setHideCastMembers={toggleCastMembers}
            />
          }
        </Grid>
        <Grid item xs={12} className={classes.buttonRow}>
          {hidePhotos ? <Button variant="contained" onClick={() => togglePhotos(false)} color='primary'>Show Photos</Button> :
            <Photos
              showId={show._id}
              setHidePhotos={togglePhotos}
            />
          }
        </Grid>
      </Grid>
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    paddingBottom: 40,
    background: 'rgb(220, 220, 220)'
  },

  editButton: {
    marginRight: 5
  },

  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: 'auto',
  },

  name: {
    margin: 'auto',
    width: 'fitContent',
  },

  productionMemberButton: {
    margin: 10,
  },

  castMemberButton: {
    margin: 10,
  },

  showPhotoButton: {
    margin: 10,
  },

  photArea: {
    marginTop: 20,
  },

  buttonRow: {
    minHeight: 50,
    marginTop: 5,
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    show: state.shows.byIds[props.id],
    deleting: state.shows.deleting,
    hideProductionMembers: state.shows.byIds[props.id]?.hideProductionMembers ?? true,
    hideCastMembers: state.shows.byIds[props.id]?.hideCastMembers ?? true,
    hidePhotos: state.shows.byIds[props.id]?.hidePhotos ?? true,
    error: state.shows.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteShowAction,
  deleteShowSuccessAction,
  deleteShowFailureAction,
  setCurrentShowAction,
  setHideProductionMembersAction,
  setHideCastMembersAction,
  setHidePhotosAction,
  addDisabledDND,
  removeDisabledDND,
})(withStyles(style)(ShowCard));

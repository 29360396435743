import {
    SET_CURRENT_PHOTO,

    SET_PHOTOS,
    SET_PHOTOS_SUCCESS,
    SET_PHOTOS_FAILURE,

    ADD_PHOTO,
    ADD_PHOTO_SUCCESS,
    ADD_PHOTO_FAILURE,

    EDIT_PHOTO,
    EDIT_PHOTO_SUCCESS,
    EDIT_PHOTO_FAILURE,

    DELETE_PHOTO,
    DELETE_PHOTO_SUCCESS,
    DELETE_PHOTO_FAILURE,

    SAVE_PHOTO_ORDER,
    SAVE_PHOTO_ORDER_SUCCESS,
    SAVE_PHOTO_ORDER_FAILURE,
} from '../actionTypes';

export const setPhotosAction = () => ({
    type: SET_PHOTOS,
})

export const setPhotosSuccessAction = (showId, photos) => ({
    type: SET_PHOTOS_SUCCESS,
    payload: {
        showId,
        photos,
    }
})

export const setPhotosFailureAction = error => ({
    type: SET_PHOTOS_FAILURE,
    payload: {
        error,
    }
})

export const deletePhotoAction = () => ({
    type: DELETE_PHOTO,
})

export const deletePhotoSuccessAction = (photo) => ({
    type: DELETE_PHOTO_SUCCESS,
    payload: {
        photo,
    }
})

export const deletePhotoFailureAction = error => ({
    type: DELETE_PHOTO_FAILURE,
    payload: {
        error
    }
})

export const addPhotoAction = () => ({
    type: ADD_PHOTO,
})

export const addPhotoSuccessAction = (photo) => ({
    type: ADD_PHOTO_SUCCESS,
    payload: {
        photo,
    }
})

export const addPhotoFailureAction = error => ({
    type: ADD_PHOTO_FAILURE,
    payload: {
        error
    }
})

export const editPhotoAction = () => ({
    type: EDIT_PHOTO,
})

export const editPhotoSuccessAction = (photo) => ({
    type: EDIT_PHOTO_SUCCESS,
    payload: {
        photo,
    }
})

export const editPhotoFailureAction = error => ({
    type: EDIT_PHOTO_FAILURE,
    payload: {
        error
    }
})

export const setCurrentPhotoAction = uuid => ({
    type: SET_CURRENT_PHOTO,
    payload: {
        uuid
    }
})

export const setSavePhotoOrderAction = () => ({
    type: SAVE_PHOTO_ORDER
})

export const setSavePhotoOrderSuccessAction = ids => ({
    type: SAVE_PHOTO_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSavePhotoOrderFailureAction = error => ({
    type: SAVE_PHOTO_ORDER_FAILURE,
    payload: {
        error
    }
})

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import PhotoCard from '../../Component/PhotoCard';
import config from '../../config';
import { setScreenAction } from '../../Redux/Actions/app';
import { setCurrentShowAction } from '../../Redux/Actions/shows';
import {
  setPhotosAction,
  setPhotosSuccessAction,
  setPhotosFailureAction,
  setCurrentPhotoAction,
  setSavePhotoOrderAction,
  setSavePhotoOrderSuccessAction,
  setSavePhotoOrderFailureAction,
} from '../../Redux/Actions/photos';
import DragAndDrop from '../DragAndDrop';

function Photos(props) {
  const {
    classes,
    accessToken,
    showId,
    fetched,
    photoGallery,
    loading,
    error,
    setHidePhotos,
    setCurrentShowAction,
    setScreenAction,
    setPhotosAction,
    setPhotosSuccessAction,
    setPhotosFailureAction,
    setCurrentPhotoAction,
    setSavePhotoOrderAction,
    setSavePhotoOrderSuccessAction,
    setSavePhotoOrderFailureAction,
  } = props;

  const [localPhotos, setLocalPhotos] = useState(photoGallery);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalPhotos(photoGallery.map(photo => photo._id));
  }, [photoGallery]);

  useEffect(() => {
    if (!fetched) {
      setPhotosAction();
      fetch(`${config.api}/api/show/${showId}/photos`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setPhotosSuccessAction(showId, result.data)
          },
          (error) => {
            setPhotosFailureAction(error)
          }
        );
    }
  }, [fetched, showId, setPhotosAction, setPhotosSuccessAction, setPhotosFailureAction]);

  const newPhoto = () => {
    setCurrentShowAction(showId);
    setCurrentPhotoAction(null);
    setScreenAction('new_photo');
  }

  const saveOrder = () => {
    setSavePhotoOrderAction();

    fetch(`${config.api}/api/photos/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        photoIds: localPhotos,
        showUuid: showId,
      }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            setSavePhotoOrderFailureAction(result.error);
            return;
          }

          setSavePhotoOrderSuccessAction(localPhotos);
          setShowOrderSaveButton(false);
        },
        (error) => {
          // connection errors
          setSavePhotoOrderFailureAction(error);
        }
      );
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={() => setHidePhotos(true)} color='primary'>Hide Photos</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={newPhoto}>New photo</Button>
        </Grid>
      </Grid>
    );
  };

  if (loading || !fetched) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  return (
    <Container className={classes.root}>
      {renderButtons()}
      <DragAndDrop
        local={localPhotos}
        setLocal={setLocalPhotos}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='photo'
        parentId={showId}
        Card={PhotoCard}
      />
      {error ? <Error message='Could not get photos' alignment='center' /> : ''}
    </Container>
  );
}

const style = {
  root: {
    marginTop: 5
  },
}

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.photos.fetched[props.showId] || false,
    photoGallery: state.shows.byIds[props.showId].photoGallery,
    loading: state.photos.loading,
    error: state.photos.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setPhotosAction,
  setPhotosSuccessAction,
  setPhotosFailureAction,
  setCurrentPhotoAction,
  setCurrentShowAction,
  setSavePhotoOrderAction,
  setSavePhotoOrderSuccessAction,
  setSavePhotoOrderFailureAction,
})(withStyles(style)(Photos));

import {
  SET_CURRENT_PHOTO,

  SET_PHOTOS,
  SET_PHOTOS_SUCCESS,
  SET_PHOTOS_FAILURE,

  ADD_PHOTO,
  ADD_PHOTO_SUCCESS,
  ADD_PHOTO_FAILURE,

  EDIT_PHOTO,
  EDIT_PHOTO_SUCCESS,
  EDIT_PHOTO_FAILURE,

  DELETE_PHOTO,
  DELETE_PHOTO_SUCCESS,
  DELETE_PHOTO_FAILURE,

  SAVE_PHOTO_ORDER,
  SAVE_PHOTO_ORDER_SUCCESS,
  SAVE_PHOTO_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
    allIds: [],
    byIds: {},
    current: null,
    fetched: {},
    loading: false,
    deleting: false,
    error: null,
};

export default function photosReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PHOTOS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_PHOTOS_SUCCESS: {
      const { showId, photos } = action.payload;
      const { allIds, byIds } = process(photos);

      return {
        ...state,
        fetched: {
          ...state.fetched,
          [showId]: true,
        },
        allIds: [...state.allIds, ...allIds],
        byIds: {...state.byIds, ...byIds},
        loading: false,
        error: null,
      };
    }

    case SET_PHOTOS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ADD_PHOTO: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_PHOTO_SUCCESS: {
      const { photo } = action.payload;
      const newAllIds = [...state.allIds, photo._id];
      const newByIds = { [photo._id]: photo, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_PHOTO_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_PHOTO: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_PHOTO_SUCCESS: {
      const { photo } = action.payload;
      const newByIds = { ...state.byIds, [photo._id]: photo };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_PHOTO_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_PHOTO: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_PHOTO_SUCCESS: {
      const { photo } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== photo._id);
      const { [photo._id]: deletedPhoto, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_PHOTO_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SET_CURRENT_PHOTO: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case SAVE_PHOTO_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_PHOTO_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_PHOTO_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, Grid, Card, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addProductionMemberAction,
    addProductionMemberSuccessAction,
    addProductionMemberFailureAction,
    editProductionMemberAction,
    editProductionMemberSuccessAction,
    editProductionMemberFailureAction,
} from '../../Redux/Actions/productionMembers';

function NewProductionMember(props) {
    const {
        classes,
        productionMember,
        next,
        showId,
        loading,
        error,
        accessToken,
        addProductionMemberAction,
        addProductionMemberSuccessAction,
        addProductionMemberFailureAction,
        editProductionMemberAction,
        editProductionMemberSuccessAction,
        editProductionMemberFailureAction,
        setScreenAction,
    } = props;

    const [image, setImage] = useState(productionMember ? productionMember.image.replace(`${config.api}/`, '') : null);
    const [name, setName] = useState(productionMember ? productionMember.name : '');
    const [role, setRole] = useState(productionMember ? productionMember.role : '');
    const [bio, setBio] = useState(productionMember ? productionMember.bio : '');
    const [order, setOrder] = useState(productionMember ? productionMember.order : next);

    const onSave = () => {
        const payload = {
            show: showId,
            image,
            name,
            role,
            bio,
            order,
        };

        addProductionMemberAction();

        fetch(`${config.api}/api/production-member`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addProductionMemberFailureAction(error);
                        return;
                    }
                    setImage(null);
                    setName('');
                    setRole('');
                    setBio('');
                    setOrder(order + 1);

                    payload._id = result._id;
                    payload.image = result.image;

                    addProductionMemberSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addProductionMemberFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: productionMember._id,
            show: showId,
            image,
            name,
            role,
            bio,
            order,
        };

        editProductionMemberAction();

        fetch(`${config.api}/api/production-member/${productionMember._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ productionMember: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editProductionMemberFailureAction(error);
                        return;
                    }

                    payload.image = result.image;

                    editProductionMemberSuccessAction(payload);
                    setScreenAction('season');
                },
                (error) => {
                    // connection errors
                    editProductionMemberFailureAction(error);
                }
            );
    }

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setImage(uploadPath);
        }
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.textField}
                            value={image && `${config.api}/${image}?${new Date().getTime()}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" id="name" label="name" className={classes.textField} value={name} onChange={event => setName(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" id="role" label="role" className={classes.textField} value={role} onChange={event => setRole(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <ReactQuill placeholder="bio" className={classes.textField} value={bio} onChange={setBio} disabled={loading} />
                    </Grid>
                    {
                        productionMember === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving ProductionMember'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} startIcon={<SaveIcon />} >Update</Button>}
                                {error ? <Error message={'Problem Saving ProductionMember'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.accessToken,
        productionMember: state.productionMembers.byIds[state.productionMembers.current],
        next: state.productionMembers.allIds.length,
        showId: state.shows.current,
        loading: state.productionMembers.loading,
        error: state.productionMembers.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addProductionMemberAction,
    addProductionMemberSuccessAction,
    addProductionMemberFailureAction,
    editProductionMemberAction,
    editProductionMemberSuccessAction,
    editProductionMemberFailureAction,
})(withStyles(style)(NewProductionMember));

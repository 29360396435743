import {
  SET_CURRENT_GOAL,

  SET_GOALS,
  SET_GOALS_SUCCESS,
  SET_GOALS_FAILURE,

  ADD_GOAL,
  ADD_GOAL_SUCCESS,
  ADD_GOAL_FAILURE,

  EDIT_GOAL,
  EDIT_GOAL_SUCCESS,
  EDIT_GOAL_FAILURE,

  DELETE_GOAL,
  DELETE_GOAL_SUCCESS,
  DELETE_GOAL_FAILURE,
  SAVE_GOAL_ORDER,
  SAVE_GOAL_ORDER_SUCCESS,
  SAVE_GOAL_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
    allIds: [],
    byIds: {},
    current: null,
    fetched: false,
    loading: false,
    deleting: false,
    error: null,
};

export default function goalsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_GOALS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_GOALS_SUCCESS: {
      const { goals } = action.payload;
      const { allIds, byIds } = process(goals);

      return {
        ...state,
        allIds,
        byIds,
        loading: false,
        fetched: true,
        error: null,
      };
    }

    case SET_GOALS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case SET_CURRENT_GOAL: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case ADD_GOAL: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_GOAL_SUCCESS: {
      const { goal } = action.payload;
      const newAllIds = [...state.allIds, goal._id];
      const newByIds = { [goal._id]: goal, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_GOAL_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_GOAL: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_GOAL_SUCCESS: {
      const { goal } = action.payload;
      const newByIds = { ...state.byIds, [goal._id]: goal };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_GOAL_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_GOAL: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_GOAL_SUCCESS: {
      const { goal } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== goal._id);
      const { [goal._id]: deletedGoal, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_GOAL_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SAVE_GOAL_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_GOAL_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_GOAL_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }


    default:
      return state;
  }
}

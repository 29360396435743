import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Container, Grid } from '@material-ui/core';
import Loader from '../../Component/Loader';
import DragAndDrop from '../DragAndDrop';
import Error from '../../Component/Error';
import {
  setAuditionsAction,
  setAuditionsSuccessAction,
  setAuditionsFailureAction,
  setCurrentAuditionAction,
  setSaveAuditionOrderAction,
  setSaveAuditionOrderSucessAction,
  setSaveAuditionOrderFailureAction
} from '../../Redux/Actions/auditions';
import config from '../../config';
import { setScreenAction } from '../../Redux/Actions/app';
import AuditionCard from '../../Component/AuditionCard';

function Audition(props) {
  const {
    fetched,
    loading,
    error,
    accessToken,
    auditions,
    setAuditionsAction,
    setAuditionsSuccessAction,
    setAuditionsFailureAction,
    setCurrentAuditionAction,
    setSaveAuditionOrderAction,
    setSaveAuditionOrderSucessAction,
    setSaveAuditionOrderFailureAction,
      setScreenAction,
  } = props;

  const [localAuditions, setLocalAuditions] = useState(auditions);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalAuditions(auditions)
  }, [auditions]);

  useEffect(() => {
    if (!fetched) {
      setAuditionsAction();

      fetch(`${config.api}/api/auditions`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          result => {
            setAuditionsSuccessAction(result.data);
          },
          error => {
            setAuditionsFailureAction(error);
          }
        );
    }
  }, [
    fetched,
    setAuditionsAction,
    setAuditionsSuccessAction,
    setAuditionsFailureAction
  ]);

  const newAudition = () => {
    setCurrentAuditionAction(null);
    setScreenAction('new_audition');
  }

  const saveOrder = () => {
    setSaveAuditionOrderAction();

    fetch(`${config.api}/api/auditions/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ auditionIds: localAuditions }),
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.error) {
            setSaveAuditionOrderFailureAction(result.error);
            return
          }

          setSaveAuditionOrderSucessAction(localAuditions);
          setShowOrderSaveButton(false);
        },
        error => {
          setSaveAuditionOrderFailureAction(error);
        }
      )
  };

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant='contained' onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant='contained' onClick={newAudition}>New Audition</Button>
        </Grid>
      </Grid>
    );
  }

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localAuditions}
        setLocal={setLocalAuditions}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='audition'
        Card={AuditionCard}
      />
      {error && <Error message='Could not get auditions' alignment='center' />}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.auditions.fetched,
    loading: state.auditions.loading,
    error: state.auditions.error,
    auditions: state.auditions.allIds,
  };
};

export default connect(mapStateToProps, {
  setAuditionsAction,
  setAuditionsSuccessAction,
  setAuditionsFailureAction,
  setCurrentAuditionAction,
  setSaveAuditionOrderAction,
  setSaveAuditionOrderSucessAction,
  setSaveAuditionOrderFailureAction,
  setScreenAction,
})(Audition);

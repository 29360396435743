import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, TextField, Grid, Card, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addUpcomingAction,
    addUpcomingSuccessAction,
    addUpcomingFailureAction,
    editUpcomingAction,
    editUpcomingSuccessAction,
    editUpcomingFailureAction,
} from '../../Redux/Actions/upcomings';

function NewUpcoming(props) {
    const {
        classes,
        upcoming,
        next,
        loading,
        error,
        accessToken,
        addUpcomingAction,
        addUpcomingSuccessAction,
        addUpcomingFailureAction,
        editUpcomingAction,
        editUpcomingSuccessAction,
        editUpcomingFailureAction,
        setScreenAction,
    } = props;

    const [name, setName] = useState(upcoming ? upcoming.name : '');
    const [poster, setPoster] = useState(upcoming ? upcoming.poster.replace(`${config.api}/`, '') : null);
    const [description, setDescription] = useState(upcoming ? upcoming.description : '');
    const [hasTickets, setHasTickets] = useState(upcoming ? upcoming.hasTickets : false);
    const [order, setOrder] = useState(upcoming ? upcoming.order : next);

    const onSave = () => {
        const payload = {
            name,
            poster,
            description,
            hasTickets,
            order,
        };

        addUpcomingAction();

        fetch(`${config.api}/api/upcoming`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addUpcomingFailureAction(result.error);
                        return;
                    }

                    setName('');
                    setPoster(null);
                    setDescription('');
                    setHasTickets(false);
                    setOrder(order + 1);

                    payload._id = result.data._id;
                    payload.poster = result.data.poster;

                    addUpcomingSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addUpcomingFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: upcoming._id,
            name,
            poster,
            description,
            hasTickets,
            order,
        };

        editUpcomingAction();

        fetch(`${config.api}/api/upcoming/${upcoming._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ upcoming: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editUpcomingFailureAction(result.error);
                        return;
                    }

                    payload.poster = result.poster;

                    editUpcomingSuccessAction(payload);
                    setScreenAction('upcoming');
                },
                (error) => {
                    // connection errors
                    editUpcomingFailureAction(error);
                }
            );
    }

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setPoster(uploadPath);
        }
    }

    const onChangeCheckbox = (event) => {
        setHasTickets(event.target.checked);
    };

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.textField}
                            value={poster && `${config.api}/${poster}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Name" className={classes.textField} value={name} onChange={event => setName(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <ReactQuill theme="snow" placeholder="Description" className={classes.textField} value={description} onChange={setDescription} disabled={loading} />
                    </Grid>
                    <Grid item>
                    <FormControlLabel
                        value="tickets"
                        control={<Checkbox checked={hasTickets} value={hasTickets} onChange={onChangeCheckbox} inputProps={{ 'aria-label': 'primary checkbox' }} />}
                        label="Tickets"
                        labelPlacement="end"
                        />

                    </Grid>
                    {
                        upcoming === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving Member'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} startIcon={<SaveIcon />} >Update</Button>}
                                {error ? <Error message={'Problem Saving Member'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
      accessToken: state.auth.accessToken,
      upcoming: state.upcomings.byIds[state.upcomings.current],
      next: state.upcomings.allIds.length,
      loading: state.upcomings.loading,
      error: state.upcomings.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addUpcomingAction,
    addUpcomingSuccessAction,
    addUpcomingFailureAction,
    editUpcomingAction,
    editUpcomingSuccessAction,
    editUpcomingFailureAction,
})(withStyles(style)(NewUpcoming));

import {
  SET_CURRENT_PRODUCTION_MEMBER,

  SET_PRODUCTION_MEMBERS,
  SET_PRODUCTION_MEMBERS_SUCCESS,
  SET_PRODUCTION_MEMBERS_FAILURE,

  ADD_PRODUCTION_MEMBER,
  ADD_PRODUCTION_MEMBER_SUCCESS,
  ADD_PRODUCTION_MEMBER_FAILURE,

  EDIT_PRODUCTION_MEMBER,
  EDIT_PRODUCTION_MEMBER_SUCCESS,
  EDIT_PRODUCTION_MEMBER_FAILURE,

  DELETE_PRODUCTION_MEMBER,
  DELETE_PRODUCTION_MEMBER_SUCCESS,
  DELETE_PRODUCTION_MEMBER_FAILURE,

  SAVE_PRODUCTION_ORDER,
  SAVE_PRODUCTION_ORDER_SUCCESS,
  SAVE_PRODUCTION_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
  allIds: [],
  byIds: {},
  fetched: {},
  current: null,
  loading: false,
  deleting: false,
  error: null,
};

export default function productionMembersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCTION_MEMBERS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_PRODUCTION_MEMBERS_SUCCESS: {
      const { showId, productionMembers } = action.payload;
      const { allIds, byIds } = process(productionMembers);

      return {
        ...state,
        fetched: {
          ...state.fetched,
          [showId]: true,
        },
        allIds: [...state.allIds, ...allIds],
        byIds: { ...state.byIds, ...byIds },
        loading: false,
        error: null,
      };
    }

    case SET_PRODUCTION_MEMBERS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ADD_PRODUCTION_MEMBER: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_PRODUCTION_MEMBER_SUCCESS: {
      const { productionMember } = action.payload;
      const newAllIds = [...state.allIds, productionMember._id];
      const newByIds = { [productionMember._id]: productionMember, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_PRODUCTION_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_PRODUCTION_MEMBER: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_PRODUCTION_MEMBER_SUCCESS: {
      const { productionMember } = action.payload;
      const newByIds = { ...state.byIds, [productionMember._id]: productionMember };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_PRODUCTION_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_PRODUCTION_MEMBER: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_PRODUCTION_MEMBER_SUCCESS: {
      const { productionMember } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== productionMember._id);
      const { [productionMember._id]: deletedProductionMember, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        current: null,
        deleting: false,
        error: null,
      };
    }

    case DELETE_PRODUCTION_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SET_CURRENT_PRODUCTION_MEMBER: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case SAVE_PRODUCTION_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_PRODUCTION_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_PRODUCTION_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

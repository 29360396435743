import {
    SET_TICKETS,
    SET_TICKETS_SUCCESS,
    SET_TICKETS_FAILURE,

    SET_CURRENT_TICKET,

    ADD_TICKET,
    ADD_TICKET_SUCCESS,
    ADD_TICKET_FAILURE,

    EDIT_TICKET,
    EDIT_TICKET_SUCCESS,
    EDIT_TICKET_FAILURE,

    DELETE_TICKET,
    DELETE_TICKET_SUCCESS,
    DELETE_TICKET_FAILURE,

    SAVE_TICKET_ORDER,
    SAVE_TICKET_ORDER_SUCCESS,
    SAVE_TICKET_ORDER_FAILURE,
} from '../actionTypes';

export const setTicketsAction = () => ({
    type: SET_TICKETS,
})

export const setTicketsSuccessAction = tickets => ({
    type: SET_TICKETS_SUCCESS,
    payload: {
        tickets
    }
})

export const setTicketsFailureAction = error => ({
    type: SET_TICKETS_FAILURE,
    payload: {
        error
    }
})

export const setCurrentTicketAction = uuid => ({
    type: SET_CURRENT_TICKET,
    payload: {
        uuid
    }
})

export const deleteTicketAction = () => ({
    type: DELETE_TICKET,
})

export const deleteTicketSuccessAction = ticket => ({
    type: DELETE_TICKET_SUCCESS,
    payload: {
        ticket
    }
})

export const deleteTicketFailureAction = error => ({
    type: DELETE_TICKET_FAILURE,
    payload: {
        error
    }
})

export const addTicketAction = () => ({
    type: ADD_TICKET,
})

export const addTicketSuccessAction = ticket => ({
    type: ADD_TICKET_SUCCESS,
    payload: {
        ticket
    }
})

export const addTicketFailureAction = error => ({
    type: ADD_TICKET_FAILURE,
    payload: {
        error
    }
})

export const editTicketAction = () => ({
    type: EDIT_TICKET,
})

export const editTicketSuccessAction = ticket => ({
    type: EDIT_TICKET_SUCCESS,
    payload: {
        ticket
    }
})

export const editTicketFailureAction = error => ({
    type: EDIT_TICKET_FAILURE,
    payload: {
        error
    }
})

export const setSaveTicketOrderAction = () => ({
    type: SAVE_TICKET_ORDER
})

export const setSaveTicketOrderSuccessAction = ids => ({
    type: SAVE_TICKET_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveTicketOrderFailureAction = error => ({
    type: SAVE_TICKET_ORDER_FAILURE,
    payload: {
        error
    }
})

export const ItemTypes = {
    UPCOMING: 'upcoming',
    MEMBER: 'member',
    SEASON: 'season',
    SHOW: 'show',
    PRODUCTION_MEMBER: 'production_member',
    CAST_MEMBER: 'cast_member',
    PHOTO: 'photo',
    GALLERY_PHOTO: 'gallery_photo',
  }

export const ticketCost = {
  ADULT: 15,
  SENIOR: 12,
  CHILD: 10,
}
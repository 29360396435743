import React from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, Avatar, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import { setCurrentShowAction } from '../../Redux/Actions/shows';
import {
  deletePhotoAction,
  deletePhotoSuccessAction,
  deletePhotoFailureAction,
  setCurrentPhotoAction,
} from '../../Redux/Actions/photos';

function PhotoCard(props) {
  const {
    index,
    classes,
    photo,
    deleting,
    error,
    parentId,
    accessToken,
    deletePhotoAction,
    deletePhotoSuccessAction,
    deletePhotoFailureAction,
    setCurrentPhotoAction,
    setCurrentShowAction,
    setScreenAction,
  } = props;

  const deletePhoto = () => {
    deletePhotoAction()

    fetch(`${config.api}/api/photo/${photo._id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          deletePhotoSuccessAction(photo);
        },
        (error) => {
          deletePhotoFailureAction(error);
        }
      );
  }

  const editPhoto = () => {
    setCurrentShowAction(parentId)
    setCurrentPhotoAction(photo._id);
    setScreenAction('new_photo');
  }

  if (!photo) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={editPhoto} disabled={deleting}>
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={deletePhoto} color='secondary' startIcon={<DeleteIcon />} disabled={deleting}>
            Delete
                    </Button>
          {deleting ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
        <Grid item xs={12}>
          <Avatar alt={`show image ${photo.order}`} src={`${photo.image}?${new Date().getTime()}`} className={classes.large} />
        </Grid>
      </Grid>
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },

  editButton: {
    marginRight: 5
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    photo: state.photos.byIds[props.id] || { _id: props.id },
    deleting: state.photos.deleting,
    error: state.photos.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deletePhotoAction,
  deletePhotoSuccessAction,
  deletePhotoFailureAction,
  setCurrentShowAction,
  setCurrentPhotoAction,
})(withStyles(style)(PhotoCard));

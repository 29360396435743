import {
  LOGGING_IN,
  LOG_IN_SUCCESS,
  LOG_IN_FAILURE,
  LOG_IN_UPDATE_SUCCESS,
  LOG_IN_UPDATE_FAILURE,
} from "../actionTypes";

const initialState = {
  loggedIn: false,
  accessToken: null,
  loading: false,
  error: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGGING_IN: {
      return {
        ...state,
        loading: true,
      };
    }
    case LOG_IN_SUCCESS: {
      const { accessToken } = action.payload;
      return {
        loggedIn: true,
        accessToken,
        loading: false,
        error: null,
      };
    }
    case LOG_IN_FAILURE: {
      const { error } = action.payload;
      return {
        loggedIn: false,
        accessToken: '',
        loading: false,
        error,
      };
    }
    case LOG_IN_UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null,
      };
    }
    case LOG_IN_UPDATE_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
}

import {
  SET_CURRENT_CAST_MEMBER,

  SET_CAST_MEMBERS,
  SET_CAST_MEMBERS_SUCCESS,
  SET_CAST_MEMBERS_FAILURE,

  ADD_CAST_MEMBER,
  ADD_CAST_MEMBER_SUCCESS,
  ADD_CAST_MEMBER_FAILURE,

  EDIT_CAST_MEMBER,
  EDIT_CAST_MEMBER_SUCCESS,
  EDIT_CAST_MEMBER_FAILURE,

  DELETE_CAST_MEMBER,
  DELETE_CAST_MEMBER_SUCCESS,
  DELETE_CAST_MEMBER_FAILURE,

  SAVE_CAST_MEMBER_ORDER,
  SAVE_CAST_MEMBER_ORDER_SUCCESS,
  SAVE_CAST_MEMBER_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
  allIds: [],
  byIds: {},
  current: null,
  fetched: {},
  loading: false,
  deleting: false,
  error: null,
};

export default function castMembersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CAST_MEMBERS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_CAST_MEMBERS_SUCCESS: {
      const { showId, castMembers } = action.payload;
      const { allIds, byIds } = process(castMembers);

      return {
        ...state,
        fetched: {
          ...state.fetched,
          [showId]: true,
        },
        allIds: [...state.allIds, ...allIds],
        byIds: { ...state.byIds, ...byIds },
        loading: false,
        error: null,
      };
    }

    case SET_CAST_MEMBERS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ADD_CAST_MEMBER: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_CAST_MEMBER_SUCCESS: {
      const { castMember } = action.payload;
      const newAllIds = [...state.allIds, castMember._id];
      const newByIds = { [castMember._id]: castMember, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_CAST_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_CAST_MEMBER: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_CAST_MEMBER_SUCCESS: {
      const { castMember } = action.payload;
      const newByIds = { ...state.byIds, [castMember._id]: castMember };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_CAST_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_CAST_MEMBER: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_CAST_MEMBER_SUCCESS: {
      const { castMember } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== castMember._id);
      const { [castMember._id]: deletedCastMember, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_CAST_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SET_CURRENT_CAST_MEMBER: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case SAVE_CAST_MEMBER_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_CAST_MEMBER_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_CAST_MEMBER_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

import {
    SET_CURRENT_SEASON,

    SET_SEASONS,
    SET_SEASONS_SUCCESS,
    SET_SEASONS_FAILURE,

    ADD_SEASON,
    ADD_SEASON_SUCCESS,
    ADD_SEASON_FAILURE,

    EDIT_SEASON,
    EDIT_SEASON_SUCCESS,
    EDIT_SEASON_FAILURE,

    DELETE_SEASON,
    DELETE_SEASON_SUCCESS,
    DELETE_SEASON_FAILURE,

    SAVE_SEASON_ORDER,
    SAVE_SEASON_ORDER_SUCCESS,
    SAVE_SEASON_ORDER_FAILURE,

    SET_HIDE_SHOWS
} from '../actionTypes';

export const setSeasonsAction = () => ({
    type: SET_SEASONS,
})

export const setSeasonsSuccessAction = seasons => ({
    type: SET_SEASONS_SUCCESS,
    payload: {
        seasons
    }
})

export const setSeasonsFailureAction = error => ({
    type: SET_SEASONS_FAILURE,
    payload: {
        error
    }
})

export const deleteSeasonAction = () => ({
    type: DELETE_SEASON,
})

export const deleteSeasonSuccessAction = season => ({
    type: DELETE_SEASON_SUCCESS,
    payload: {
        season
    }
})

export const deleteSeasonFailureAction = error => ({
    type: DELETE_SEASON_FAILURE,
    payload: {
        error
    }
})

export const addSeasonAction = () => ({
    type: ADD_SEASON,
})

export const addSeasonSuccessAction = season => ({
    type: ADD_SEASON_SUCCESS,
    payload: {
        season
    }
})

export const addSeasonFailureAction = error => ({
    type: ADD_SEASON_FAILURE,
    payload: {
        error
    }
})

export const editSeasonAction = () => ({
    type: EDIT_SEASON,
})

export const editSeasonSuccessAction = season => ({
    type: EDIT_SEASON_SUCCESS,
    payload: {
        season
    }
})

export const editSeasonFailureAction = error => ({
    type: EDIT_SEASON_FAILURE,
    payload: {
        error
    }
})

export const setCurrentSeasonAction = seasonId => ({
    type: SET_CURRENT_SEASON,
    payload: {
        seasonId
    }
})

export const setSaveSeasonOrderAction = () => ({
    type: SAVE_SEASON_ORDER
})

export const setSaveSeasonOrderSuccessAction = ids => ({
    type: SAVE_SEASON_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveSeasonOrderFailureAction = error => ({
    type: SAVE_SEASON_ORDER_FAILURE,
    payload: {
        error
    }
})

export const setHideShowsAction = (hideShows, seasonId) => ({
    type: SET_HIDE_SHOWS,
    payload: {
        seasonId,
        hideShows
    }
})
import { Box } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";

function DragAndDrop(props) {
	const {
		local,
		setLocal,
		setShowOrderSaveButton,
		droppableId,
		Card,
		disabledDnDs,
		parentId,
	} = props;

	const onDragEnd = (result) => {
		const newOrder = [...local];
		const sourceIndex = result.source.index;
		const destIndex = result.destination.index;
		const [removed] = newOrder.splice(sourceIndex, 1);
		newOrder.splice(destIndex, 0, removed);

		setLocal(newOrder);
		setShowOrderSaveButton(true);
	}

	if (disabledDnDs.includes(droppableId)) {
		return (
			<Box>
				{local.map((id, index) => (
					<Card
						key={id}
						index={index}
						id={id}
						parentId={parentId}
					/>
				))}
			</Box>
		)
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<Box
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={{ background: snapshot.isDraggingOver ? "lightblue" : "white" }}
					>
						{local.map((id, index) => (
							<Draggable key={id} draggableId={id} index={index}>
								{(provided) => (
									<Box
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={{ ...provided.draggableProps.style }}
									>
										<Card
											index={index}
											id={id}
											parentId={parentId}
										/>
									</Box>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</Box>
				)}
			</Droppable>
		</DragDropContext>
	)
}

const mapStateToProps = (state) => {
  return {
		disabledDnDs: state.app.disabledDnDs,
  };
};

export default connect(mapStateToProps, null)(DragAndDrop);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import NewsCard from '../../Component/NewsCard';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setNewsesAction,
  setNewsesSuccessAction,
  setNewsesFailureAction,
  setCurrentNewsAction,
  setSaveNewsOrderAction,
  setSaveNewsOrderSuccessAction,
  setSaveNewsOrderFailureAction,
} from '../../Redux/Actions/newses';
import DragAndDrop from '../DragAndDrop';

function Newses(props) {
  const {
    accessToken,
    fetched,
    newses,
    loading,
    error,
    setScreenAction,
    setNewsesAction,
    setNewsesSuccessAction,
    setNewsesFailureAction,
    setCurrentNewsAction,
    setSaveNewsOrderAction,
    setSaveNewsOrderSuccessAction,
    setSaveNewsOrderFailureAction,
  } = props;

  const [localNewses, setLocalNewses] = useState(newses);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalNewses(newses);
  }, [newses]);

  useEffect(() => {
    if (!fetched) {
      setNewsesAction();
      fetch(`${config.api}/api/newses`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setNewsesSuccessAction(result.data);
          },
          (error) => {
            setNewsesFailureAction(error);
          }
        );
    }
  }, [fetched, setNewsesAction, setNewsesFailureAction, setNewsesSuccessAction]);

  const newNews = () => {
    setCurrentNewsAction(null);
    setScreenAction('new_news');
  }

  const saveOrder = () => {
    setSaveNewsOrderAction();

    fetch(`${config.api}/api/newses/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ newsIds: localNewses }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            setSaveNewsOrderFailureAction(result.error);
            return;
          }

          setSaveNewsOrderSuccessAction(localNewses);
          setShowOrderSaveButton(false);
        },
        (error) => {
          // connection errors
          setSaveNewsOrderFailureAction(error);
        }
      );
  }

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={newNews}>New News</Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localNewses}
        setLocal={setLocalNewses}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='news'
        Card={NewsCard}
      />
      {error ? <Error message='Could not get news items' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.newses.fetched,
    loading: state.newses.loading,
    error: state.newses.error,
    newses: state.newses.allIds,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setNewsesAction,
  setNewsesSuccessAction,
  setNewsesFailureAction,
  setCurrentNewsAction,
  setSaveNewsOrderAction,
  setSaveNewsOrderSuccessAction,
  setSaveNewsOrderFailureAction,
})(Newses);

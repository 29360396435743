import {
    SET_GOALS,
    SET_GOALS_SUCCESS,
    SET_GOALS_FAILURE,

    SET_CURRENT_GOAL,

    ADD_GOAL,
    ADD_GOAL_SUCCESS,
    ADD_GOAL_FAILURE,

    EDIT_GOAL,
    EDIT_GOAL_SUCCESS,
    EDIT_GOAL_FAILURE,

    DELETE_GOAL,
    DELETE_GOAL_SUCCESS,
    DELETE_GOAL_FAILURE,
    SAVE_GOAL_ORDER,
    SAVE_GOAL_ORDER_SUCCESS,
    SAVE_GOAL_ORDER_FAILURE,
} from '../actionTypes';

export const setGoalsAction = () => ({
    type: SET_GOALS,
})

export const setGoalsSuccessAction = goals => ({
    type: SET_GOALS_SUCCESS,
    payload: {
        goals
    }
})

export const setGoalsFailureAction = error => ({
    type: SET_GOALS_FAILURE,
    payload: {
        error
    }
})

export const setCurrentGoalAction = uuid => ({
    type: SET_CURRENT_GOAL,
    payload: {
        uuid
    }
})

export const deleteGoalAction = () => ({
    type: DELETE_GOAL,
})

export const deleteGoalSuccessAction = goal => ({
    type: DELETE_GOAL_SUCCESS,
    payload: {
        goal
    }
})

export const deleteGoalFailureAction = error => ({
    type: DELETE_GOAL_FAILURE,
    payload: {
        error
    }
})

export const addGoalAction = () => ({
    type: ADD_GOAL,
})

export const addGoalSuccessAction = goal => ({
    type: ADD_GOAL_SUCCESS,
    payload: {
        goal
    }
})

export const addGoalFailureAction = error => ({
    type: ADD_GOAL_FAILURE,
    payload: {
        error
    }
})

export const editGoalAction = () => ({
    type: EDIT_GOAL,
})

export const editGoalSuccessAction = goal => ({
    type: EDIT_GOAL_SUCCESS,
    payload: {
        goal
    }
})

export const editGoalFailureAction = error => ({
    type: EDIT_GOAL_FAILURE,
    payload: {
        error
    }
})

export const setSaveGoalOrderAction = () => ({
    type: SAVE_GOAL_ORDER,
})

export const setSaveGoalOrderSuccessAction = ids => ({
    type: SAVE_GOAL_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveGoalOrderFailureAction = error => ({
    type: SAVE_GOAL_ORDER_FAILURE,
    payload: {
        error
    }
})


import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, Grid, Card, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addPhotoAction,
    addPhotoSuccessAction,
    addPhotoFailureAction,
    editPhotoAction,
    editPhotoSuccessAction,
    editPhotoFailureAction,
} from '../../Redux/Actions/photos'

function NewPhoto(props) {
    const {
        classes,
        next,
        photo,
        showId,
        loading,
        error,
        addPhotoAction,
        addPhotoSuccessAction,
        addPhotoFailureAction,
        editPhotoAction,
        editPhotoSuccessAction,
        editPhotoFailureAction,
        setScreenAction,
        accessToken,
    } = props;

    const [image, setImage] = useState(photo ? photo.image.replace(`${config.api}/`, '') : null);
    const [order, setOrder] = useState(photo ? photo.order : next);

    const onSave = () => {
        const payload = {
            show: showId,
            image,
            order,
        };

        addPhotoAction();

        fetch(`${config.api}/api/photo`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addPhotoFailureAction(error);
                        return;
                    }
                    setImage(null);
                    setOrder(order + 1);

                    payload._id = result.data._id;
                    payload.image = result.data.image;

                    addPhotoSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addPhotoFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: photo._id,
            show: showId,
            image,
            order,
        };

        editPhotoAction();

        fetch(`${config.api}/api/photo/${photo._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ photo: payload, showUuid: showId }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editPhotoFailureAction(error);
                        return;
                    }

                    payload.image = result.image;

                    editPhotoSuccessAction(payload);
                    setScreenAction('season');
                },
                (error) => {
                    // connection errors
                    editPhotoFailureAction(error);
                }
            );
    }

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setImage(uploadPath);
        }
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.margin20}
                            value={image && `${config.api}/${image}?${new Date().getTime()}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    {
                        photo === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.margin20} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving Photo'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.margin20} startIcon={<SaveIcon />} >Update</Button>}
                                {error ? <Error message={'Problem Saving Photo'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    margin20: {
        margin: 20
    }
}

const mapStateToProps = state => {
    return {
        next: state.photos.allIds.length,
        photo: state.photos.byIds[state.photos.current],
        showId: state.shows.current,
        loading: state.photos.loading,
        error: state.photos.error,
        accessToken: state.auth.accessToken,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addPhotoAction,
    addPhotoSuccessAction,
    addPhotoFailureAction,
    editPhotoAction,
    editPhotoSuccessAction,
    editPhotoFailureAction,
})(withStyles(style)(NewPhoto));

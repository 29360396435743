import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, Button, Card, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  deleteAuditionAction,
  deleteAuditionFailureAction,
  deleteAuditionSuccessAction,
  setCurrentAuditionAction,
  setHideRolesAction,
  setSaveRoleOrderAction,
  setSaveRoleOrderSuccessAction,
  setSaveRoleOrderFailureAction,
} from '../../Redux/Actions/auditions';
import { addDisabledDND, removeDisabledDND, setScreenAction } from '../../Redux/Actions/app';
import config from '../../config';
import Error from '../Error';
import DragAndDrop from '../../Container/DragAndDrop';
import RoleCard from '../RoleCard';

function AuditionCard(props) {
  const {
    accessToken,
    classes,
    index,
    audition,
    hideRoles,
    setCurrentAuditionAction,
    setScreenAction,
    deleteAuditionAction,
    deleteAuditionSuccessAction,
    deleteAuditionFailureAction,
    setHideRolesAction,
    setSaveRoleOrderAction,
    setSaveRoleOrderSuccessAction,
    setSaveRoleOrderFailureAction,
    addDisabledDND,
    removeDisabledDND,
    deleting,
    error,
  } = props;

  const [localRoles, setLocalRoles] = useState(audition ? audition.roles.map((_role, index) => `${index}`) : []);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  const editAudition = () => {
    setCurrentAuditionAction(audition._id);
    setScreenAction('new_audition');
  }

  const deleteAudition = () => {
    deleteAuditionAction();

    fetch(`${config.api}/api/auditions/${audition._id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        result => {
          deleteAuditionSuccessAction(audition);
        },
        error => {
          deleteAuditionFailureAction(error);
        }
      );
  }

  const saveRoleOrder = () => {
    setSaveRoleOrderAction();

    const updatedRoles = localRoles.map(roleIndex => audition.roles[+roleIndex]);

    fetch(`${config.api}/api/audition/${audition._id}/roles/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        roles: updatedRoles,
      }),
    })
      .then(res => res.json())
      .then(
        result => {
          if (result.error) {
            setSaveRoleOrderFailureAction(result.error);
            return;
          }

          setSaveRoleOrderSuccessAction(updatedRoles, audition._id);
          setShowOrderSaveButton(false);
        },
        error => {
          setSaveRoleOrderFailureAction(error);
        }
      );
  }

  const toggleRoles = () => {
    if (hideRoles) {
      addDisabledDND('audition');
    } else {
      removeDisabledDND('audition');
    }

    setHideRolesAction(!hideRoles, audition._id);
  }

  if (!audition) {
    return null;
  }

  return (
    <Card className={classes.card}>
      <div>{index + 1}</div>
      <Grid
        container
        justifyContent='flex-end'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveRoleOrder}>Save Role Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant='contained' onClick={editAudition}>Edit</Button>
        </Grid>
        <Grid item>
          <Button variant='contained' color='secondary' startIcon={<DeleteIcon />} onClick={deleteAudition} disabled={deleting}>Delete</Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction='column'
        alignItems='center'
      >
        <Grid item>
          <Avatar alt={audition.title} src={`${audition.image}?${new Date().getTime()}`} />
        </Grid>
        <Grid item>
          <Typography>{audition.title}</Typography>
        </Grid>
      </Grid>
      <Button variant='contained' onClick={toggleRoles}>{hideRoles ? 'Show Roles' : 'Hide Roles'}</Button>
      {!hideRoles && (
        <DragAndDrop
          local={localRoles}
          setLocal={setLocalRoles}
          setShowOrderSaveButton={setShowOrderSaveButton}
          droppableId='roles'
          parentId={audition._id}
          Card={RoleCard}
        />
      )}
      {error && <Error message={'Problem Deleting'} alignment={'flex-end'} />}
    </Card>
  );
}

const style = {
  card: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },
};

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    audition: state.auditions.byIds[props.id],
    hideRoles: state.auditions.byIds[props.id]?.hideRoles ?? true,
    deleting: state.auditions.deleting,
    error: state.auditions.error,
  };
};

export default connect(mapStateToProps, {
  setCurrentAuditionAction,
  setScreenAction,
  deleteAuditionAction,
  deleteAuditionSuccessAction,
  deleteAuditionFailureAction,
  setSaveRoleOrderAction,
  setSaveRoleOrderSuccessAction,
  setSaveRoleOrderFailureAction,
  setHideRolesAction,
  addDisabledDND,
  removeDisabledDND,
})(withStyles(style)(AuditionCard));

import React from 'react';
import CachedIcon from '@material-ui/icons/Cached';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';

function Loader(props) {
    return (
        <Container>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                <CachedIcon className={props.classes.icon} style={{fontSize: props.size}}/>
            </Grid>
        </Container>
    );
}

const style = {
    icon: {
        animation: '$rotate 3s infinite linear'
    },

    "@keyframes rotate": {
        from: {
            transform: "rotate(0deg)"
        },
        to: {
            transform: "rotate(-360deg)"
        }
    }
};

export default withStyles(style)(Loader);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import config from '../../config';
import GalleryPhotoCard from '../../Component/GalleryPhotoCard';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    setPhotosAction,
    setPhotosSuccessAction,
    setPhotosFailureAction,
    setCurrentPhotoAction,
    setSavePhotoOrderAction,
    setSavePhotoOrderSuccessAction,
    setSavePhotoOrderFailureAction,
} from '../../Redux/Actions/photoGallery';
import DragAndDrop from '../DragAndDrop';

function PhotoGallery(props) {
    const {
        accessToken,
        photos,
        fetched,
        loading,
        error,
        setScreenAction,
        setPhotosAction,
        setPhotosSuccessAction,
        setPhotosFailureAction,
        setCurrentPhotoAction,
        setSavePhotoOrderAction,
        setSavePhotoOrderSuccessAction,
        setSavePhotoOrderFailureAction,
    } = props;

    const [localPhotos, setLocalPhotos] = useState(photos);
    const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

    useEffect(() => {
        setLocalPhotos(photos);
    }, [photos]);


    useEffect(() => {
        if (!fetched) {
            setPhotosAction();

            fetch(`${config.api}/api/photo-gallery`, {cache: 'no-store'})
                .then(res => res.json())
                .then(
                    (result) => {
                        setPhotosSuccessAction(result.data);
                    },
                    (error) => {
                        setPhotosFailureAction(error);
                    }
                )
        }
    }, [fetched, setPhotosAction, setPhotosSuccessAction, setPhotosFailureAction]);

    const newPhoto = () => {
        setCurrentPhotoAction(null);
        setScreenAction('new_gallery_photo');
    }

    const saveOrder = () => {
        setSavePhotoOrderAction();

        fetch(`${config.api}/api/photo-gallery/order`, {
          method: 'PATCH',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'x-access-token': accessToken,
          },
          body: JSON.stringify({ photoIds: localPhotos }),
        })
          .then(res => res.json())
          .then(
            (result) => {
                if (result.error) {
                    // result error
                    setSavePhotoOrderFailureAction(result.error);
                    return;
                }

                setSavePhotoOrderSuccessAction(localPhotos);
                setShowOrderSaveButton(false);
            },
            (error) => {
                // connection errors
                setSavePhotoOrderFailureAction(error);
            }
        );
    }

    if (loading) {
        return (
            <Container>
                <Loader size={80} />
            </Container>
        )
    }

    const renderButtons = () => {
        return (
            <Grid
                container
                direction='row'
                justifyContent='flex-end'
                alignItems='flex-start'
                spacing={2}
            >
                {showOrderSaveButton && (
                    <Grid item>
                        <Button variant="contained" onClick={saveOrder}>Save Order</Button>
                    </Grid>
                )}
                <Grid item>
                    <Button variant="contained" onClick={newPhoto}>New Photo</Button>
                </Grid>
            </Grid>
        );
    };

    return (
        <Container>
            {renderButtons()}
            <DragAndDrop
                local={localPhotos}
                setLocal={setLocalPhotos}
                setShowOrderSaveButton={setShowOrderSaveButton}
                droppableId='photo-gallery'
                Card={GalleryPhotoCard}
            />
            {error ? <Error message='Could not get photos' alignment='center' /> : ''}
        </Container>
    );
}

const mapStateToProps = state => {
    return {
        accessToken: state.auth.accessToken,
        photos: state.photoGallery.allIds,
        fetched: state.photoGallery.fetched,
        loading: state.photoGallery.loading,
        error: state.photoGallery.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    setPhotosAction,
    setPhotosSuccessAction,
    setPhotosFailureAction,
    setCurrentPhotoAction,
    setSavePhotoOrderAction,
    setSavePhotoOrderSuccessAction,
    setSavePhotoOrderFailureAction,
})(PhotoGallery);

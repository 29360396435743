export const runOnEnter = (event, callback) => {
    if (event.key === 'Enter') {
        callback()
    }
}

export const process = (dataArray) => {
    const allIds = [];
    const byIds = {};

    dataArray.forEach(data => {
        if (!allIds.includes(data._id)) {
            allIds.push(data._id);
        }
        byIds[data._id] = data;
    });


    return {
        allIds,
        byIds,
    }
}
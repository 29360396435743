import {
	SET_AUDITIONS,
	SET_AUDITIONS_SUCCESS,
	SET_AUDITIONS_FAILURE,

	ADD_AUDITION,
	ADD_AUDITION_SUCCESS,
	ADD_AUDITION_FAILURE,

	EDIT_AUDITION,
	EDIT_AUDITION_SUCCESS,
	EDIT_AUDITION_FAILURE,

	DELETE_AUDITION,
	DELETE_AUDITION_SUCCESS,
	DELETE_AUDITION_FAILURE,

	SET_CURRENT_AUDITION,

	SAVE_AUDITION_ORDER,
	SAVE_AUDITION_ORDER_SUCCESS,
	SAVE_AUDITION_ORDER_FAILURE,

	SET_HIDE_ROLES,

	SAVE_AUDITION_ROLE_ORDER,
	SAVE_AUDITION_ROLE_ORDER_SUCCESS,
	SAVE_AUDITION_ROLE_ORDER_FAILURE,
} from '../actionTypes';

export const setAuditionsAction = () => ({
	type: SET_AUDITIONS,
});

export const setAuditionsSuccessAction = auditions => ({
	type: SET_AUDITIONS_SUCCESS,
	payload: {
			auditions
	}
});

export const setAuditionsFailureAction = error => ({
	type: SET_AUDITIONS_FAILURE,
	payload: {
			error
	}
});

export const setCurrentAuditionAction = uuid => ({
	type: SET_CURRENT_AUDITION,
	payload: {
		uuid
	}
});

export const addAuditionAction = () => ({
	type: ADD_AUDITION
});

export const addAuditionSuccessAction = audition => ({
	type: ADD_AUDITION_SUCCESS,
	payload: {
		audition
	}
});

export const addAuditionFailureAction = error => ({
	type: ADD_AUDITION_FAILURE,
	payload: {
		error
	}
});

export const setSaveAuditionOrderAction = () => ({
	type: SAVE_AUDITION_ORDER
});

export const setSaveAuditionOrderSucessAction = ids => ({
	type: SAVE_AUDITION_ORDER_SUCCESS,
	payload: {
		ids
	}
});

export const setSaveAuditionOrderFailureAction = error => ({
	type: SAVE_AUDITION_ORDER_FAILURE,
	payload: {
		error
	}
});

export const deleteAuditionAction = () => ({
	type: DELETE_AUDITION,
});

export const deleteAuditionSuccessAction = audition => ({
	type: DELETE_AUDITION_SUCCESS,
	payload: {
		audition
	}
});

export const deleteAuditionFailureAction = error => ({
	type: DELETE_AUDITION_FAILURE,
	payload: {
		error
	}
});

export const setHideRolesAction = (hideRoles, auditionId) => ({
	type: SET_HIDE_ROLES,
	payload: {
		auditionId,
		hideRoles,
	}
});

export const setSaveRoleOrderAction = () => ({
	type: SAVE_AUDITION_ROLE_ORDER
});

export const setSaveRoleOrderSuccessAction = (rolEs, auditionId) => ({
	type: SAVE_AUDITION_ROLE_ORDER_SUCCESS,
	payload: {
		auditionId,
		rolEs,
	}
});

export const setSaveRoleOrderFailureAction = error => ({
	type: SAVE_AUDITION_ROLE_ORDER_FAILURE,
	payload: {
		error
	}
});

export const editAuditionAction = () => ({
	type: EDIT_AUDITION
});

export const editAuditionSuccessAction = audition => ({
	type: EDIT_AUDITION_SUCCESS,
	payload: {
		audition,
	}
});

export const editAuditionFailureAction = error => ({
	type: EDIT_AUDITION_FAILURE,
	payload: {
		error
	}
});

import {
  SET_SHOWS,
  SET_SHOWS_SUCCESS,
  SET_SHOWS_FAILURE,

  ADD_SHOW,
  ADD_SHOW_SUCCESS,
  ADD_SHOW_FAILURE,

  EDIT_SHOW,
  EDIT_SHOW_SUCCESS,
  EDIT_SHOW_FAILURE,

  DELETE_SHOW,
  DELETE_SHOW_SUCCESS,
  DELETE_SHOW_FAILURE,

  ADD_PRODUCTION_MEMBER_SUCCESS,
  DELETE_PRODUCTION_MEMBER_SUCCESS,

  ADD_CAST_MEMBER_SUCCESS,
  DELETE_CAST_MEMBER_SUCCESS,

  ADD_PHOTO_SUCCESS,
  DELETE_PHOTO_SUCCESS,

  SET_CURRENT_SHOW,

  SAVE_SHOW_ORDER,
  SAVE_SHOW_ORDER_SUCCESS,
  SAVE_SHOW_ORDER_FAILURE,

  SET_HIDE_PRODUCTION_MEMBERS,
  SET_HIDE_CAST_MEMBERS,
  SET_HIDE_PHOTOS
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
  allIds: [],
  byIds: {},
  current: null,
  fetched: {},
  loading: false,
  deleting: false,
  error: null,
};

export default function showsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOWS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_SHOWS_SUCCESS: {
      const { seasonId, shows } = action.payload;
      const { allIds, byIds } = process(shows);

      return {
        ...state,
        fetched: {
          ...state.fetched,
          [seasonId]: true,
        },
        allIds: [...state.allIds, ...allIds],
        byIds: { ...state.byIds, ...byIds },
        loading: false,
        error: null,
      };
    }

    case SET_SHOWS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ADD_SHOW: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_SHOW_SUCCESS: {
      const { show } = action.payload;
      const newAllIds = [...state.allIds, show._id];
      const newByIds = { [show._id]: show, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_SHOW_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_SHOW: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_SHOW_SUCCESS: {
      const { show } = action.payload;
      const newByIds = { ...state.byIds, [show._id]: show };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_SHOW_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_SHOW: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_SHOW_SUCCESS: {
      const { show } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== show._id);
      const { [show._id]: deletedShow, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_SHOW_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case ADD_PRODUCTION_MEMBER_SUCCESS: {
      const { productionMember } = action.payload;
      const newProductionList = [...state.byIds[productionMember.show].productionList, productionMember._id];

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [productionMember.show]: {
            ...state.byIds[productionMember.show],
            productionList: newProductionList,
          }
        },
      };
    }

    case DELETE_PRODUCTION_MEMBER_SUCCESS: {
      const { productionMember } = action.payload;
      const newProductionList = state.byIds[productionMember.show].productionList.filter(thisId => thisId !== productionMember._id);

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [productionMember.show]: {
            ...state.byIds[productionMember.show],
            productionList: newProductionList,
          }
        },
      };
    }

    case ADD_CAST_MEMBER_SUCCESS: {
      const { castMember } = action.payload;
      const newCastList = [...state.byIds[castMember.show].castList, castMember._id];

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [castMember.show]: {
            ...state.byIds[castMember.show],
            castList: newCastList,
          }
        },
      };
    }

    case DELETE_CAST_MEMBER_SUCCESS: {
      const { castMember } = action.payload;
      const newCastList = state.byIds[castMember.show].castList.filter(thisId => thisId !== castMember._id);

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [castMember.show]: {
            ...state.byIds[castMember.show],
            castList: newCastList,
          }
        },
      };
    }

    case ADD_PHOTO_SUCCESS: {
      const { photo } = action.payload;
      const newPhotoGallery = [...state.byIds[photo.show].photoGallery, photo._id];

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [photo.show]: {
            ...state.byIds[photo.show],
            photoGallery: newPhotoGallery,
          }
        },
      };
    }

    case DELETE_PHOTO_SUCCESS: {
      const { photo } = action.payload;
      const newPhotoGallery = state.byIds[photo.show].photoGallery.filter(thisId => thisId !== photo._id);

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [photo.show]: {
            ...state.byIds[photo.show],
            photoGallery: newPhotoGallery,
          }
        },
      };
    }

    case SET_CURRENT_SHOW: {
      const { showId } = action.payload;

      return {
        ...state,
        current: showId
      };
    }

    case SAVE_SHOW_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_SHOW_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_SHOW_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    case SET_HIDE_PRODUCTION_MEMBERS: {
      const { hideProductionMembers, showId } = action.payload;

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [showId]: {
            ...state.byIds[showId],
            hideProductionMembers,
          }
        },
      }
    }

    case SET_HIDE_CAST_MEMBERS: {
      const { hideCastMembers, showId } = action.payload;

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [showId]: {
            ...state.byIds[showId],
            hideCastMembers,
          }
        },
      }
    }

    case SET_HIDE_PHOTOS: {
      const { hidePhotos, showId } = action.payload;

      return {
        ...state,
        byIds: {
          ...state.byIds,
          [showId]: {
            ...state.byIds[showId],
            hidePhotos,
          }
        },
      }
    }

    default:
      return state;
  }
}

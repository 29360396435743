import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SeasonCard from '../../Component/SeasonCard';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { Container, Grid, Button } from '@material-ui/core';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setSeasonsAction,
  setSeasonsSuccessAction,
  setSeasonsFailureAction,
  setSaveSeasonOrderAction,
  setSaveSeasonOrderSuccessAction,
  setSaveSeasonOrderFailureAction,
} from '../../Redux/Actions/seasons';
import DragAndDrop from '../DragAndDrop';

function Seasons(props) {
  const {
    accessToken,
    fetched,
    loading,
    error,
    seasons,
    setScreenAction,
    setSeasonsAction,
    setSeasonsSuccessAction,
    setSeasonsFailureAction,
    setSaveSeasonOrderAction,
    setSaveSeasonOrderSuccessAction,
    setSaveSeasonOrderFailureAction,
  } = props;

  const [localSeasons, setLocalSeasons] = useState(seasons);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalSeasons(seasons);
  }, [seasons]);

  useEffect(() => {
    if (!fetched) {
      setSeasonsAction();
      fetch(`${config.api}/api/seasons`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setSeasonsSuccessAction(result.data);
          },
          (error) => {
            setSeasonsFailureAction(error);
          }
        );
    }
  }, [fetched, setSeasonsAction, setSeasonsSuccessAction, setSeasonsFailureAction]);

  const saveOrder = () => {
    setSaveSeasonOrderAction();

    fetch(`${config.api}/api/seasons/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ seasonIds: localSeasons }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            setSaveSeasonOrderFailureAction(result.error);
            return;
          }

          setSaveSeasonOrderSuccessAction(localSeasons);
          setShowOrderSaveButton(false);
        },
        (error) => {
          // connection errors
          setSaveSeasonOrderFailureAction(error);
        }
      );
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={() => setScreenAction('new_season')}>New Season</Button>
        </Grid>
      </Grid>
    );
  };

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localSeasons}
        setLocal={setLocalSeasons}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='season'
        Card={SeasonCard}
      />
      {error ? <Error message='Could not get seasons' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.seasons.fetched,
    loading: state.seasons.loading,
    error: state.seasons.error,
    seasons: state.seasons.allIds,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setSeasonsAction,
  setSeasonsSuccessAction,
  setSeasonsFailureAction,
  setSaveSeasonOrderAction,
  setSaveSeasonOrderSuccessAction,
  setSaveSeasonOrderFailureAction,
})(Seasons);

import {
    SET_CURRENT_SEASON,

    SET_SEASONS,
    SET_SEASONS_SUCCESS,
    SET_SEASONS_FAILURE,

    ADD_SEASON,
    ADD_SEASON_SUCCESS,
    ADD_SEASON_FAILURE,

    EDIT_SEASON,
    EDIT_SEASON_SUCCESS,
    EDIT_SEASON_FAILURE,

    DELETE_SEASON,
    DELETE_SEASON_SUCCESS,
    DELETE_SEASON_FAILURE,

    ADD_SHOW_SUCCESS,
    DELETE_SHOW_SUCCESS,

    SAVE_SEASON_ORDER,
    SAVE_SEASON_ORDER_SUCCESS,
    SAVE_SEASON_ORDER_FAILURE,

    SET_HIDE_SHOWS
  } from "../actionTypes";
  import { process } from '../../helper';

  const initialState = {
      allIds: [],
      byIds: {},
      current: null,
      fetched: false,
      loading: false,
      deleting: false,
      hideShows: true,
      error: null,
  };

  export default function seasonsReducer(state = initialState, action) {
    switch (action.type) {
      case SET_SEASONS: {

        return {
          ...state,
          loading: true,
          error: null,
        };
      }

      case SET_SEASONS_SUCCESS: {
        const { seasons } = action.payload;
        const { allIds, byIds } = process(seasons);

        return {
          ...state,
          allIds,
          byIds,
          loading: false,
          fetched: true,
          error: null,
        };
      }

      case SET_SEASONS_FAILURE: {
        const { error } = action.payload;

        return {
          ...state,
          loading: false,
          error,
        };
      }

      case ADD_SEASON: {

        return {
          ...state,
          loading: true,
          error: null,
        };
      }

      case ADD_SEASON_SUCCESS: {
        const { season } = action.payload;
        const newAllIds = [...state.allIds, season._id];
        const newByIds = { [season._id]: season, ...state.byIds };

        return {
          ...state,
          allIds: newAllIds,
          byIds: newByIds,
          loading: false,
          error: null,
        };
      }

      case ADD_SEASON_FAILURE: {
        const { error } = action.payload;

        return {
          ...state,
          loading: false,
          error,
        };
      }

      case EDIT_SEASON: {

        return {
          ...state,
          loading: true,
          error: null,
        };
      }

      case EDIT_SEASON_SUCCESS: {
        const { season } = action.payload;
        const newByIds = { ...state.byIds, [season._id]: season };

        return {
          ...state,
          byIds: newByIds,
          loading: false,
          error: null,
        };
      }

      case EDIT_SEASON_FAILURE: {
        const { error } = action.payload;

        return {
          ...state,
          loading: false,
          error,
        };
      }

      case DELETE_SEASON: {

        return {
          ...state,
          deleting: true,
          error: null,
        };
      }

      case DELETE_SEASON_SUCCESS: {
        const { season } = action.payload;
        const newIds = state.allIds.filter(thisId => thisId !== season._id);
        const { [season._id]: deletedSeason, ...remainingIds } = state.byIds;

        return {
          ...state,
          allIds: newIds,
          byIds: remainingIds,
          deleting: false,
          error: null,
        };
      }

      case DELETE_SEASON_FAILURE: {
        const { error } = action.payload;

        return {
          ...state,
          deleting: false,
          error,
        };
      }

      case ADD_SHOW_SUCCESS: {
        const { show } = action.payload;

        return {
          ...state,
          byIds: {
            ...state.byIds,
            [show.season]: {
              ...state.byIds[show.season],
              shows: [...state.byIds[show.season].shows, show._id],
            }
          }
        };
      }

      case DELETE_SHOW_SUCCESS: {
        const { show } = action.payload;

        const season = Object.values(state.byIds).find(season => season.shows.includes(show._id))._id;

        const newShowList = state.byIds[season].shows.filter(showUuid => showUuid !== show._id);

        return {
          ...state,
          byIds: {
            ...state.byIds,
            [season]: {
              ...state.byIds[season],
              shows: newShowList,
            }
          }
        };
      }

      case SET_CURRENT_SEASON: {
        const { seasonId } = action.payload;

        return {
          ...state,
          current: seasonId
        };
      }

      case SAVE_SEASON_ORDER: {
        return {
          ...state,
          loading: true,
          error: null,
        }
      }

      case SAVE_SEASON_ORDER_SUCCESS: {
        const { ids } = action.payload;
        return {
          ...state,
          allIds: [...ids],
          loading: false,
        }
      }

      case SAVE_SEASON_ORDER_FAILURE: {
        const { error } = action.payload;

        return {
          ...state,
          loading: false,
          error,
        }
      }

      case SET_HIDE_SHOWS: {
        const { seasonId, hideShows } = action.payload;

        return {
          ...state,
          byIds: {
            ...state.byIds,
            [seasonId]: {
              ...state.byIds[seasonId],
              hideShows
            }
          }
        }
      }

      default:
        return state;
    }
  }

import {
  SET_SCREEN,
  ADD_DISABLED_DND,
  REMOVE_DISABLED_DND,
  CLEAR_DISABLED_DND,
} from '../actionTypes';

const initialState = {
  screen: '',
  disabledDnDs: [],
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SCREEN: {
      const { screen } = action.payload;

      return {
        ...state,
        screen,
      };
    }

    case ADD_DISABLED_DND: {
      const { id } = action.payload;

      return {
        ...state,
        disabledDnDs: [...state.disabledDnDs, id],
      };
    }

    case REMOVE_DISABLED_DND: {
      const { id } = action.payload;
      const disabledDnDs = [...state.disabledDnDs];
      const index = disabledDnDs.findIndex(thisId => thisId === id);

      if (index !== -1) {
        disabledDnDs.splice(index, 1);
      }

      return {
        ...state,
        disabledDnDs,
      };
    }

    case CLEAR_DISABLED_DND: {
      return {
        ...state,
        disabledDnDs: [],
      };
    }

    default:
      return state;
  }
}

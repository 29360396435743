import {
    SET_NEWSES,
    SET_NEWSES_SUCCESS,
    SET_NEWSES_FAILURE,

    SET_CURRENT_NEWS,

    ADD_NEWS,
    ADD_NEWS_SUCCESS,
    ADD_NEWS_FAILURE,

    EDIT_NEWS,
    EDIT_NEWS_SUCCESS,
    EDIT_NEWS_FAILURE,

    DELETE_NEWS,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAILURE,

    SAVE_NEWS_ORDER,
    SAVE_NEWS_ORDER_SUCCESS,
    SAVE_NEWS_ORDER_FAILURE,
} from '../actionTypes';

export const setNewsesAction = () => ({
    type: SET_NEWSES,
})

export const setNewsesSuccessAction = newses => ({
    type: SET_NEWSES_SUCCESS,
    payload: {
        newses
    }
})

export const setNewsesFailureAction = error => ({
    type: SET_NEWSES_FAILURE,
    payload: {
        error
    }
})

export const setCurrentNewsAction = uuid => ({
    type: SET_CURRENT_NEWS,
    payload: {
        uuid
    }
})

export const deleteNewsAction = () => ({
    type: DELETE_NEWS,
})

export const deleteNewsSuccessAction = news => ({
    type: DELETE_NEWS_SUCCESS,
    payload: {
        news
    }
})

export const deleteNewsFailureAction = error => ({
    type: DELETE_NEWS_FAILURE,
    payload: {
        error
    }
})

export const addNewsAction = () => ({
    type: ADD_NEWS,
})

export const addNewsSuccessAction = news => ({
    type: ADD_NEWS_SUCCESS,
    payload: {
        news
    }
})

export const addNewsFailureAction = error => ({
    type: ADD_NEWS_FAILURE,
    payload: {
        error
    }
})

export const editNewsAction = () => ({
    type: EDIT_NEWS,
})

export const editNewsSuccessAction = news => ({
    type: EDIT_NEWS_SUCCESS,
    payload: {
        news
    }
})

export const editNewsFailureAction = error => ({
    type: EDIT_NEWS_FAILURE,
    payload: {
        error
    }
})

export const setSaveNewsOrderAction = () => ({
    type: SAVE_NEWS_ORDER
})

export const setSaveNewsOrderSuccessAction = ids => ({
    type: SAVE_NEWS_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveNewsOrderFailureAction = error => ({
    type: SAVE_NEWS_ORDER_FAILURE,
    payload: {
        error
    }
})

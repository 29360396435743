import {
  SET_CURRENT_NEWS,

  SET_NEWSES,
  SET_NEWSES_SUCCESS,
  SET_NEWSES_FAILURE,

  ADD_NEWS,
  ADD_NEWS_SUCCESS,
  ADD_NEWS_FAILURE,

  EDIT_NEWS,
  EDIT_NEWS_SUCCESS,
  EDIT_NEWS_FAILURE,

  DELETE_NEWS,
  DELETE_NEWS_SUCCESS,
  DELETE_NEWS_FAILURE,

  SAVE_NEWS_ORDER,
  SAVE_NEWS_ORDER_SUCCESS,
  SAVE_NEWS_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
    allIds: [],
    byIds: {},
    current: null,
    fetched: false,
    loading: false,
    deleting: false,
    error: null,
};

export default function newsesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_NEWSES: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_NEWSES_SUCCESS: {
      const { newses } = action.payload;
      const { allIds, byIds } = process(newses);

      return {
        ...state,
        allIds,
        byIds,
        loading: false,
        fetched: true,
        error: null,
      };
    }

    case SET_NEWSES_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case SET_CURRENT_NEWS: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case ADD_NEWS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_NEWS_SUCCESS: {
      const { news } = action.payload;
      const newAllIds = [...state.allIds, news._id];
      const newByIds = { [news._id]: news, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_NEWS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_NEWS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_NEWS_SUCCESS: {
      const { news } = action.payload;
      const newByIds = { ...state.byIds, [news._id]: news };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_NEWS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_NEWS: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_NEWS_SUCCESS: {
      const { news } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== news._id);
      const { [news._id]: deletedNews, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_NEWS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SAVE_NEWS_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_NEWS_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_NEWS_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

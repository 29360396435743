import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import ProductionMemberCard from '../../Component/ProductionMemberCard';
import config from '../../config';
import { setScreenAction } from '../../Redux/Actions/app';
import { setCurrentShowAction } from '../../Redux/Actions/shows'
import {
  setProductionMembersAction,
  setProductionMembersSuccessAction,
  setProductionMembersFailureAction,
  setCurrentProductionMemberAction,
  setSaveProductionOrderAction,
  setSaveProductionOrderSuccessAction,
  setSaveProductionOrderFailureAction,
} from '../../Redux/Actions/productionMembers';
import DragAndDrop from '../DragAndDrop';

function ProductionMembers(props) {
  const {
    classes,
    accessToken,
    showId,
    fetched,
    productionList,
    loading,
    error,
    setHideProductionMembers,
    setScreenAction,
    setProductionMembersAction,
    setProductionMembersSuccessAction,
    setProductionMembersFailureAction,
    setCurrentProductionMemberAction,
    setCurrentShowAction,
    setSaveProductionOrderAction,
    setSaveProductionOrderSuccessAction,
    setSaveProductionOrderFailureAction,
  } = props;

  const [localProductionList, setLocalProductionList] = useState(productionList);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalProductionList(productionList.map((productionMember) => productionMember['_id']));
  }, [productionList]);

  useEffect(() => {
    if (!fetched) {
      setProductionMembersAction();
      fetch(`${config.api}/api/show/${showId}/production-members`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setProductionMembersSuccessAction(showId, result.data);
          },
          (error) => {
            setProductionMembersFailureAction(error);
          }
        );
    }
  }, [fetched, showId, setProductionMembersAction, setProductionMembersSuccessAction, setProductionMembersFailureAction]);

  const newProductionMember = () => {
    setCurrentShowAction(showId);
    setCurrentProductionMemberAction(null);
    setScreenAction('new_production_member');
  }

  const saveOrder = () => {
    setSaveProductionOrderAction();

    fetch(`${config.api}/api/production-members/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({
        productionMemberIds: localProductionList,
        showUuid: showId,
      }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            setSaveProductionOrderFailureAction(result.error);
            return;
          }

          setSaveProductionOrderSuccessAction(localProductionList);
          setShowOrderSaveButton(false);
        },
        (error) => {
          // connection errors
          setSaveProductionOrderFailureAction(error);
        }
      );
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={() => setHideProductionMembers(true)} color='primary'>Hide Production Members</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={newProductionMember}>New Production Member</Button>
        </Grid>
      </Grid>
    );
  };

  if (loading || !fetched) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  console.log('localProductionList', localProductionList)
  return (
    <Container className={classes.root}>
      {renderButtons()}
      <DragAndDrop
        local={localProductionList}
        setLocal={setLocalProductionList}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='production'
        Card={ProductionMemberCard}
      />
      {error ? <Error message='Could not get shows' alignment='center' /> : ''}
    </Container>
  );
}

const style = {
  root: {
    marginTop: 5,
  },
}

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.productionMembers.fetched[props.showId] || false,
    productionList: state.shows.byIds[props.showId].productionList,
    loading: state.productionMembers.loading,
    error: state.productionMembers.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setProductionMembersAction,
  setProductionMembersSuccessAction,
  setProductionMembersFailureAction,
  setCurrentShowAction,
  setCurrentProductionMemberAction,
  setSaveProductionOrderAction,
  setSaveProductionOrderSuccessAction,
  setSaveProductionOrderFailureAction,
})(withStyles(style)(ProductionMembers));

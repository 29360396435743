import {
    SET_PHOTO_GALLERIES,
    SET_PHOTO_GALLERIES_SUCCESS,
    SET_PHOTO_GALLERIES_FAILURE,

    ADD_PHOTO_GALLERY,
    ADD_PHOTO_GALLERY_SUCCESS,
    ADD_PHOTO_GALLERY_FAILURE,

    EDIT_PHOTO_GALLERY,
    EDIT_PHOTO_GALLERY_SUCCESS,
    EDIT_PHOTO_GALLERY_FAILURE,

    DELETE_PHOTO_GALLERY,
    DELETE_PHOTO_GALLERY_SUCCESS,
    DELETE_PHOTO_GALLERY_FAILURE,

    SET_CURRENT_PHOTO_GALLERY,

    SAVE_PHOTO_GALLERY_ORDER,
    SAVE_PHOTO_GALLERY_ORDER_SUCCESS,
    SAVE_PHOTO_GALLERY_ORDER_FAILURE,
} from '../actionTypes';

export const setPhotosAction = () => ({
    type: SET_PHOTO_GALLERIES,
})

export const setPhotosSuccessAction = photos => ({
    type: SET_PHOTO_GALLERIES_SUCCESS,
    payload: {
        photos
    }
})

export const setPhotosFailureAction = error => ({
    type: SET_PHOTO_GALLERIES_FAILURE,
    payload: {
        error
    }
})

export const deletePhotoAction = () => ({
    type: DELETE_PHOTO_GALLERY,
})

export const deletePhotoSuccessAction = photo => ({
    type: DELETE_PHOTO_GALLERY_SUCCESS,
    payload: {
        photo
    }
})

export const deletePhotoFailureAction = error => ({
    type: DELETE_PHOTO_GALLERY_FAILURE,
    payload: {
        error
    }
})

export const addPhotoAction = () => ({
    type: ADD_PHOTO_GALLERY,
})

export const addPhotoSuccessAction = photo => ({
    type: ADD_PHOTO_GALLERY_SUCCESS,
    payload: {
        photo
    }
})

export const addPhotoFailureAction = error => ({
    type: ADD_PHOTO_GALLERY_FAILURE,
    payload: {
        error
    }
})

export const editPhotoAction = () => ({
    type: EDIT_PHOTO_GALLERY,
})

export const editPhotoSuccessAction = photo => ({
    type: EDIT_PHOTO_GALLERY_SUCCESS,
    payload: {
        photo
    }
})

export const editPhotoFailureAction = error => ({
    type: EDIT_PHOTO_GALLERY_FAILURE,
    payload: {
        error
    }
})

export const setCurrentPhotoAction = uuid => ({
    type: SET_CURRENT_PHOTO_GALLERY,
    payload: {
        uuid
    }
})

export const setSavePhotoOrderAction = () => ({
    type: SAVE_PHOTO_GALLERY_ORDER,
})

export const setSavePhotoOrderSuccessAction = ids => ({
    type: SAVE_PHOTO_GALLERY_ORDER_SUCCESS,
    payload: {
        ids,
    },
})
export const setSavePhotoOrderFailureAction = error => ({
    type: SAVE_PHOTO_GALLERY_ORDER_FAILURE,
    payload: {
        error,
    },
})

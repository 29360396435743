import {
  SET_UPCOMINGS,
  SET_UPCOMINGS_SUCCESS,
  SET_UPCOMINGS_FAILURE,

  ADD_UPCOMING,
  ADD_UPCOMING_SUCCESS,
  ADD_UPCOMING_FAILURE,

  EDIT_UPCOMING,
  EDIT_UPCOMING_SUCCESS,
  EDIT_UPCOMING_FAILURE,

  DELETE_UPCOMING,
  DELETE_UPCOMING_SUCCESS,
  DELETE_UPCOMING_FAILURE,

  SET_CURRENT_UPCOMING,

  SAVE_UPCOMING_ORDER,
  SAVE_UPCOMING_ORDER_SUCCESS,
  SAVE_UPCOMING_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
    allIds: [],
    byIds: {},
    current: null,
    fetched: false,
    loading: false,
    deleting: false,
    error: null,
};

export default function upcomingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UPCOMINGS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_UPCOMINGS_SUCCESS: {
      const { upcomings } = action.payload;
      const { allIds, byIds } = process(upcomings);

      return {
        ...state,
        allIds,
        byIds,
        loading: false,
        fetched: true,
        error: null,
      };
    }

    case SET_UPCOMINGS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ADD_UPCOMING: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_UPCOMING_SUCCESS: {
      const { upcoming } = action.payload;
      const newAllIds = [...state.allIds, upcoming._id];
      const newByIds = { [upcoming._id]: upcoming, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_UPCOMING_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_UPCOMING: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_UPCOMING_SUCCESS: {
      const { upcoming } = action.payload;
      const newByIds = { ...state.byIds, [upcoming._id]: upcoming };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_UPCOMING_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_UPCOMING: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_UPCOMING_SUCCESS: {
      const { upcoming } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== upcoming._id);
      const { [upcoming._id]: deletedUpcoming, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_UPCOMING_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SET_CURRENT_UPCOMING: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case SAVE_UPCOMING_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }

    case SAVE_UPCOMING_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_UPCOMING_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import Shows from '../../Container/Shows';
import config from '../../config';
import Loader from '../Loader';
import Error from '../Error';
import {
  setScreenAction,
  addDisabledDND,
  removeDisabledDND
} from '../../Redux/Actions/app';
import {
  deleteSeasonAction,
  deleteSeasonSuccessAction,
  deleteSeasonFailureAction,
  setCurrentSeasonAction,
  setHideShowsAction
} from '../../Redux/Actions/seasons';

function SeasonCard(props) {
  const {
    index,
    id,
    classes,
    season,
    deleting,
    error,
    accessToken,
    deleteSeasonAction,
    deleteSeasonSuccessAction,
    deleteSeasonFailureAction,
    setCurrentSeasonAction,
    setScreenAction,
    addDisabledDND,
    removeDisabledDND,
    hideShows,
    setHideShowsAction,
  } = props;

  const toggleShows = (value) => {
    if (value) {
      removeDisabledDND('season');
    } else {
      addDisabledDND('season');
    }

    setHideShowsAction(value, id);
  }

  const deleteSeason = (id) => {
    deleteSeasonAction();

    fetch(`${config.api}/api/season/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            deleteSeasonFailureAction(result.error);
            return;
          }

          deleteSeasonSuccessAction(season);
        },
        (error) => {
          deleteSeasonFailureAction(error);
        }
      );
  }

  const editSeason = (uuid) => {
    setCurrentSeasonAction(uuid);
    setScreenAction('new_season');
  }

  if (!season) {
    return null;
  }

  const disableDelete = season.shows.length !== 0;

  return (
    <Paper
      className={classes.root}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={() => editSeason(season._id)} disabled={deleting}>
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => deleteSeason(season._id)} color='secondary' startIcon={<DeleteIcon />} disabled={deleting || disableDelete}>
            Delete
          </Button>
          {deleting ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography>{season.name}</Typography>
      </Grid>
      {hideShows ? <Button variant="contained" onClick={() => toggleShows(false)} color='primary'>Show Shows</Button> :
        <Shows
          seasonId={season._id}
          setHideShows={toggleShows}
        />
      }
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)',
  },

  editButton: {
    marginRight: 5
  },

  addShowButton: {
    float: 'right',
    marginTop: 17,
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    season: state.seasons.byIds[props.id],
    deleting: state.seasons.deleting,
    hideShows: state.seasons.byIds[props.id]?.hideShows ?? true,
    error: state.seasons.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteSeasonAction,
  deleteSeasonSuccessAction,
  deleteSeasonFailureAction,
  setCurrentSeasonAction,
  addDisabledDND,
  removeDisabledDND,
  setHideShowsAction,
})(withStyles(style)(SeasonCard));

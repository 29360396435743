import {
    SET_CURRENT_PRODUCTION_MEMBER,

    SET_PRODUCTION_MEMBERS,
    SET_PRODUCTION_MEMBERS_SUCCESS,
    SET_PRODUCTION_MEMBERS_FAILURE,

    ADD_PRODUCTION_MEMBER,
    ADD_PRODUCTION_MEMBER_SUCCESS,
    ADD_PRODUCTION_MEMBER_FAILURE,

    EDIT_PRODUCTION_MEMBER,
    EDIT_PRODUCTION_MEMBER_SUCCESS,
    EDIT_PRODUCTION_MEMBER_FAILURE,

    DELETE_PRODUCTION_MEMBER,
    DELETE_PRODUCTION_MEMBER_SUCCESS,
    DELETE_PRODUCTION_MEMBER_FAILURE,

    SAVE_PRODUCTION_ORDER,
    SAVE_PRODUCTION_ORDER_SUCCESS,
    SAVE_PRODUCTION_ORDER_FAILURE,
} from '../actionTypes';

export const setProductionMembersAction = () => ({
    type: SET_PRODUCTION_MEMBERS,
})

export const setProductionMembersSuccessAction = (showId, productionMembers) => ({
    type: SET_PRODUCTION_MEMBERS_SUCCESS,
    payload: {
        showId,
        productionMembers,
    }
})

export const setProductionMembersFailureAction = error => ({
    type: SET_PRODUCTION_MEMBERS_FAILURE,
    payload: {
        error,
    }
})

export const deleteProductionMemberAction = () => ({
    type: DELETE_PRODUCTION_MEMBER,
})

export const deleteProductionMemberSuccessAction = (productionMember) => ({
    type: DELETE_PRODUCTION_MEMBER_SUCCESS,
    payload: {
        productionMember,
    }
})

export const deleteProductionMemberFailureAction = error => ({
    type: DELETE_PRODUCTION_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const addProductionMemberAction = () => ({
    type: ADD_PRODUCTION_MEMBER,
})

export const addProductionMemberSuccessAction = (productionMember) => ({
    type: ADD_PRODUCTION_MEMBER_SUCCESS,
    payload: {
        productionMember,
    }
})

export const addProductionMemberFailureAction = error => ({
    type: ADD_PRODUCTION_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const editProductionMemberAction = () => ({
    type: EDIT_PRODUCTION_MEMBER,
})

export const editProductionMemberSuccessAction = (productionMember) => ({
    type: EDIT_PRODUCTION_MEMBER_SUCCESS,
    payload: {
        productionMember,
    }
})

export const editProductionMemberFailureAction = error => ({
    type: EDIT_PRODUCTION_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const setCurrentProductionMemberAction = uuid => ({
    type: SET_CURRENT_PRODUCTION_MEMBER,
    payload: {
        uuid
    }
})

export const setSaveProductionOrderAction = () => ({
    type: SAVE_PRODUCTION_ORDER
})

export const setSaveProductionOrderSuccessAction = ids => ({
    type: SAVE_PRODUCTION_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveProductionOrderFailureAction = error => ({
    type: SAVE_PRODUCTION_ORDER_FAILURE,
    payload: {
        error
    }
})

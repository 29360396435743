import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container, TextField, Grid, Card, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addSeasonAction,
    addSeasonSuccessAction,
    addSeasonFailureAction,
    editSeasonAction,
    editSeasonSuccessAction,
    editSeasonFailureAction
} from '../../Redux/Actions/seasons';

function NewSeason(props) {
    const {
        classes,
        season,
        next,
        loading,
        error,
        accessToken,
        addSeasonAction,
        addSeasonSuccessAction,
        addSeasonFailureAction,
        editSeasonAction,
        editSeasonSuccessAction,
        editSeasonFailureAction,
        setScreenAction,
    } = props;

    const [name, setName] = useState(season ? season.name : '');
    const [order, setOrder] = useState(season ? season.order : next);

    const onSave = () => {
        const payload = {
            name,
            order,
            shows: [],
        };

        addSeasonAction();

        fetch(`${config.api}/api/season`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addSeasonFailureAction(error);
                        return;
                    }
                    payload._id = result.data._id;

                    setName('');
                    setOrder(order + 1);

                    addSeasonSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addSeasonFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: season._id,
            name,
            order,
            shows: season.shows,
        };

        editSeasonAction();

        fetch(`${config.api}/api/season/${season._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ season: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editSeasonFailureAction(result.error);
                        return;
                    }

                    editSeasonSuccessAction(payload);
                    setScreenAction('season');
                },
                (error) => {
                    editSeasonFailureAction(error);
                }
            );
    }


    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <TextField variant="outlined" id="name" label="Name" className={classes.textField} value={name} onChange={event => setName(event.target.value)} disabled={loading} />
                    </Grid>
                    {
                        season === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving season'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} >Update</Button>}
                                {error ? <Error message={'Problem Saving season'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.accessToken,
        season: state.seasons.byIds[state.seasons.current],
        next: state.seasons.allIds.length,
        loading: state.seasons.loading,
        error: state.seasons.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addSeasonAction,
    addSeasonSuccessAction,
    addSeasonFailureAction,
    editSeasonAction,
    editSeasonSuccessAction,
    editSeasonFailureAction
})(withStyles(style)(NewSeason));

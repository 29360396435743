import React, { useState } from 'react';
import { connect } from 'react-redux';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, Grid, Card, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addGoalAction,
    addGoalSuccessAction,
    addGoalFailureAction,
    editGoalAction,
    editGoalSuccessAction,
    editGoalFailureAction,
} from '../../Redux/Actions/goals';

function NewGoal(props) {
    const {
        classes,
        goal,
        next,
        loading,
        error,
        accessToken,
        addGoalAction,
        addGoalSuccessAction,
        addGoalFailureAction,
        editGoalAction,
        editGoalSuccessAction,
        editGoalFailureAction,
        setScreenAction,
    } = props;

    const [name, setName] = useState(goal ? goal.name : '');
    const [current, setCurrent] = useState(goal ? goal.current : 0);
    const [total, setTotal] = useState(goal ? goal.total : 0);
    const [order, setOrder] = useState(goal ? goal.order : next);

    const onSave = () => {
        const payload = {
            current,
            name,
            total,
            order,
        };

        addGoalAction();

        fetch(`${config.api}/api/goal`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ goal: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addGoalFailureAction(result.error);
                        return;
                    }

                    setName('');
                    setCurrent(0);
                    setTotal(0);
                    setOrder(order + 1);

                    payload._id = result._id;
                    addGoalSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addGoalFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: goal._id,
            current,
            name,
            total,
            order,
        };

        editGoalAction();

        fetch(`${config.api}/api/goal/${goal._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ goal: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editGoalFailureAction(result.error);
                        return;
                    }

                    editGoalSuccessAction(payload);
                    setScreenAction('goals');
                },
                (error) => {
                    // connection errors
                    editGoalFailureAction(error);
                }
            );
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                >
                    <Grid item>
                        <TextField variant="outlined" label="Name" className={classes.textField} value={name} onChange={event => setName(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Current Raised" className={classes.textField} value={current} onChange={event => setCurrent(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Total Goal" className={classes.textField} value={total} onChange={event => setTotal(event.target.value)} disabled={loading} />
                    </Grid>
                    {
                        goal === undefined ? (
                            <Grid item xs={12}>
                                {loading ? <Loader size={36} /> : <Button variant='contained' color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving Goal'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                {loading ? <Loader size={36} /> : <Button variant='contained' color='primary' onClick={onUpdate} className={classes.textField} >Update</Button>}
                                {error ? <Error message={'Problem Saving Goal'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    linkCard: {
        padding: 20,
        margin: 10,
    },
    textField: {
        margin: 20
    },
    deleteButton: {
        marginTop: 30,
    },
}

const mapStateToProps = (state) => {
    return {
      accessToken: state.auth.accessToken,
      goal: state.goals.byIds[state.goals.current],
      next: state.goals.allIds.length,
      loading: state.goals.loading,
      error: state.goals.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addGoalAction,
    addGoalSuccessAction,
    addGoalFailureAction,
    editGoalAction,
    editGoalSuccessAction,
    editGoalFailureAction,
})(withStyles(style)(NewGoal));

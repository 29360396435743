import {
    LOGGING_IN,
    LOG_IN_SUCCESS,
    LOG_IN_FAILURE,
    LOG_IN_UPDATE_SUCCESS,
    LOG_IN_UPDATE_FAILURE,
} from '../actionTypes';

export const loggingInAction = () => ({
    type: LOGGING_IN,
})

export const logInSuccessAction = accessToken => ({
    type: LOG_IN_SUCCESS,
    payload: {
        accessToken
    }
})

export const logInFailureAction = error => ({
    type: LOG_IN_FAILURE,
    payload: {
        error
    }
})

export const logInUpdateSuccessAction = () => ({
    type: LOG_IN_UPDATE_SUCCESS,
})

export const logInUpdateFailureAction = error => ({
    type: LOG_IN_UPDATE_FAILURE,
    payload: {
        error
    }
})

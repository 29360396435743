import {
    SET_SCREEN,
    ADD_DISABLED_DND,
    REMOVE_DISABLED_DND,
    CLEAR_DISABLED_DND,
} from '../actionTypes';

export const setScreenAction = screen => ({
    type: SET_SCREEN,
    payload: {
        screen
    }
})

export const addDisabledDND = id => ({
    type: ADD_DISABLED_DND,
    payload: {
        id
    }
})

export const removeDisabledDND = id => ({
    type: REMOVE_DISABLED_DND,
    payload: {
        id
    }
})

export const clearDisabledDND = () => ({
    type: CLEAR_DISABLED_DND,
})

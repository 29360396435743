import React from 'react';
import { connect } from 'react-redux';
import { Paper, Grid, Typography, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import config from '../../config';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from '../Loader';
import Error from '../Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  deleteCastMemberAction,
  deleteCastMemberSuccessAction,
  deleteCastMemberFailureAction,
  setCurrentCastMemberAction,
} from '../../Redux/Actions/castMembers';

function CastMemberCard(props) {
  const {
    index,
    classes,
    castMember,
    deleting,
    error,
    accessToken,
    deleteCastMemberAction,
    deleteCastMemberSuccessAction,
    deleteCastMemberFailureAction,
    setCurrentCastMemberAction,
    setScreenAction,
  } = props;

  const deleteCastMember = () => {
    deleteCastMemberAction();

    fetch(`${config.api}/api/cast-member/${castMember._id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
    })
      .then(res => res.json())
      .then(
        (result) => {
          deleteCastMemberSuccessAction(castMember);
        },
        (error) => {
          deleteCastMemberFailureAction(error);
        }
      );
  }

  const editCastMember = () => {
    setCurrentCastMemberAction(castMember._id);
    setScreenAction('new_cast_member');
  }

  if (!castMember) {
    return null;
  }

  return (
    <Paper
      className={classes.root}
      style={{
      }}
    >
      <div>{index + 1}</div>
      <Grid container justifyContent="flex-end" spacing={4}>
        <Grid item>
          <Button variant="contained" onClick={editCastMember} disabled={deleting}>
            Edit
                    </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" onClick={() => deleteCastMember(castMember._id)} color='secondary' startIcon={<DeleteIcon />} disabled={deleting}>
            Delete
                    </Button>
          {deleting ? <Loader size={36} /> : ''}
          {error ? <Error message={'Problem Deleting'} alignment={'flex-end'} /> : ''}
        </Grid>
        <Grid item xs={12}>
          <Typography>{castMember.name}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const style = (theme) => ({
  root: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)'
  },

  editButton: {
    marginRight: 5
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
});

const mapStateToProps = (state, props) => {
  return {
    accessToken: state.auth.accessToken,
    castMember: state.castMembers.byIds[props.id],
    deleting: state.castMembers.deleting,
    error: state.castMembers.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  deleteCastMemberAction,
  deleteCastMemberSuccessAction,
  deleteCastMemberFailureAction,
  setCurrentCastMemberAction,
})(withStyles(style)(CastMemberCard));

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import MemberCard from '../../Component/MemberCard';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setMembersAction,
  setMembersSuccessAction,
  setMembersFailureAction,
  setCurrentMemberAction,
  setSaveMemberOrderAction,
  setSaveMemberOrderSuccessAction,
  setSaveMemberOrderFailureAction,
} from '../../Redux/Actions/members';
import DragAndDrop from '../DragAndDrop';

function Members(props) {
  const {
    accessToken,
    fetched,
    members,
    loading,
    error,
    setScreenAction,
    setMembersAction,
    setMembersSuccessAction,
    setMembersFailureAction,
    setCurrentMemberAction,
    setSaveMemberOrderAction,
    setSaveMemberOrderSuccessAction,
    setSaveMemberOrderFailureAction,
    } = props;

  const [localMembers, setLocalMembers] = useState(members);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalMembers(members);
  }, [members]);


  useEffect(() => {
    if (!fetched) {
      setMembersAction();
      fetch(`${config.api}/api/members`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setMembersSuccessAction(result.data);
          },
          (error) => {
            setMembersFailureAction(error);
          }
        );
    }
  }, [fetched, setMembersAction, setMembersFailureAction, setMembersSuccessAction]);

  const newMember = () => {
    setCurrentMemberAction(null);
    setScreenAction('new_member');
  }

  const saveOrder = () => {
    setSaveMemberOrderAction();

    fetch(`${config.api}/api/members/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ memberIds: localMembers }),
    })
      .then(res => res.json())
      .then(
        (result) => {
            if (result.error) {
                // result error
                setSaveMemberOrderFailureAction(result.error);
                return;
            }

            setSaveMemberOrderSuccessAction(localMembers);
            setShowOrderSaveButton(false);
        },
        (error) => {
            // connection errors
            setSaveMemberOrderFailureAction(error);
        }
    );
  }

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={newMember}>New Member</Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localMembers}
        setLocal={setLocalMembers}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='member'
        Card={MemberCard}
      />
      {error ? <Error message='Could not get members' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.members.fetched,
    loading: state.members.loading,
    error: state.members.error,
    members: state.members.allIds,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setMembersAction,
  setMembersSuccessAction,
  setMembersFailureAction,
  setCurrentMemberAction,
  setSaveMemberOrderAction,
  setSaveMemberOrderSuccessAction,
  setSaveMemberOrderFailureAction,
})(Members);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, Grid, Card, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addNewsAction,
    addNewsSuccessAction,
    addNewsFailureAction,
    editNewsAction,
    editNewsSuccessAction,
    editNewsFailureAction,
} from '../../Redux/Actions/newses';

function NewNews(props) {
    const {
        classes,
        news,
        next,
        loading,
        error,
        accessToken,
        addNewsAction,
        addNewsSuccessAction,
        addNewsFailureAction,
        editNewsAction,
        editNewsSuccessAction,
        editNewsFailureAction,
        setScreenAction,
    } = props;

    const [image, setImage] = useState(news ? news.image.replace(`${config.api}/`, '') : null);
    const [info, setInfo] = useState(news ? news.info : '');
    const [showOnHome, setShowOnHome] = useState(news ? news.showOnHome : false);
    const [order, setOrder] = useState(news ? news.order : next);

    const onSave = () => {
        const payload = {
            image,
            info,
            showOnHome,
            order,
        };

        addNewsAction();

        fetch(`${config.api}/api/news`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ news: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addNewsFailureAction(result.error);
                        return;
                    }

                    setImage(null);
                    setInfo('');
                    setShowOnHome(false);
                    setOrder(order + 1);

                    payload._id = result._id;
                    payload.image = result.image;

                    addNewsSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addNewsFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: news._id,
            image,
            info,
            showOnHome,
            order,
        };

        editNewsAction();

        fetch(`${config.api}/api/news/${news._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ news: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editNewsFailureAction(result.error);
                        return;
                    }

                    payload.image = result.image;

                    editNewsSuccessAction(payload);
                    setScreenAction('newses');
                },
                (error) => {
                    // connection errors
                    editNewsFailureAction(error);
                }
            );
    }

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setImage(uploadPath);
        }
    }

    const onChangeCheckbox = (event) => {
        setShowOnHome(event.target.checked);
    };

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.textField}
                            value={image && `${config.api}/${image}?${new Date().getTime()}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <ReactQuill theme="snow" placeholder="Info" className={classes.textField} value={info} onChange={setInfo} disabled={loading} />
                    </Grid>
                    <FormControlLabel
                        value="show"
                        control={<Checkbox checked={showOnHome} value={showOnHome} onChange={onChangeCheckbox} inputProps={{ 'aria-label': 'primary checkbox' }} />}
                        label="Show on home"
                        labelPlacement="end"
                    />
                    {
                        news === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving News'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} >Update</Button>}
                                {error ? <Error message={'Problem Saving News'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.accessToken,
        news: state.newses.byIds[state.newses.current],
        next: state.newses.allIds.length,
        loading: state.newses.loading,
        error: state.newses.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addNewsAction,
    addNewsSuccessAction,
    addNewsFailureAction,
    editNewsAction,
    editNewsSuccessAction,
    editNewsFailureAction,
})(withStyles(style)(NewNews));

import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import moment from 'moment-timezone';
import SaveIcon from '@material-ui/icons/Save';
import { Container, Grid, Card, Button, TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  addTicketAction,
  addTicketSuccessAction,
  addTicketFailureAction,
  editTicketAction,
  editTicketSuccessAction,
  editTicketFailureAction,
} from '../../Redux/Actions/tickets';
import { ticketCost } from '../../Constant';

function NewTicket(props) {
  const {
    classes,
    ticket,
    next,
    loading,
    error,
    accessToken,
    addTicketAction,
    addTicketSuccessAction,
    addTicketFailureAction,
    editTicketAction,
    editTicketSuccessAction,
    editTicketFailureAction,
    setScreenAction,
  } = props;

  const [poster, setPoster] = useState(ticket ? ticket.poster.replace(`${config.api}/`, '') : null);
  const [name, setName] = useState(ticket ? ticket.name : '');
  const [description, setDescription] = useState(ticket ? ticket.description : '');
  const [days, setDays] = useState(ticket ? ticket.days : []);
  const [order, setOrder] = useState(ticket ? ticket.order : next);

  const onSave = () => {
    const payload = {
      name,
      description,
      poster,
      order,
    };

    addTicketAction();

    payload.days = days.map(day => {
      return {
        startDate: moment.tz(day.startDate, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm'),
        dateAndTime: moment.tz(day.dateAndTime, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm'),
        ticketOptions: [
          {
            key: 'adult',
            text: 'Adult',
            price: ticketCost.ADULT,
          },
          {
            key: 'senior',
            text: 'Senior (60 & over)',
            price: ticketCost.SENIOR,
          },
          {
            key: 'child',
            text: 'Child (12 & under)',
            price: ticketCost.CHILD,
          },
        ],
        endDate: moment.tz(day.endDate, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm')
      }
    });

    fetch(`${config.api}/api/tickets`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify(payload),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            addTicketFailureAction(result.error);
            return;
          }

          setName('');
          setDescription('');
          setPoster(null);
          setDays([]);
          setOrder(order + 1);

          payload._id = result.data._id;
          payload.days = days;
          payload.poster = result.data.poster;

          addTicketSuccessAction(payload);
        },
        (error) => {
          // connection errors
          addTicketFailureAction(error);
        }
      );
  }

  const onUpdate = () => {
    const payload = {
      _id: ticket._id,
      name,
      description,
      poster,
      order,
    };

    editTicketAction();

    payload.days = days.map(day => {
      return {
        startDate: moment.tz(day.startDate, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm'),
        dateAndTime: moment.tz(day.dateAndTime, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm'),
        ticketOptions: [
          {
            key: 'adult',
            text: 'Adult',
            price: ticketCost.ADULT,
          },
          {
            key: 'senior',
            text: 'Senior (60 & over)',
            price: ticketCost.SENIOR,
          },
          {
            key: 'child',
            text: 'Child (12 & under)',
            price: ticketCost.CHILD,
          },
        ],
        endDate: moment.tz(day.endDate, 'America/Chicago').utc().format('YYYY-MM-DDTHH:mm')
      }
    });

    fetch(`${config.api}/api/ticket/${ticket._id}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ ticket: payload }),
    })
      .then(res => res.json())
      .then(
        (result) => {
          if (result.error) {
            // result error
            editTicketFailureAction(result.error);
            return;
          }

          payload.days = days;
          payload.poster = result.poster;

          editTicketSuccessAction(payload);
          setScreenAction('ticket');
        },
        (error) => {
          // connection errors
          editTicketFailureAction(error);
        }
      );
  }

  const uploadImage = (uploadPath) => {
    if (uploadPath) {
        setPoster(uploadPath);
    }
  }

  const updateStartDate = (event) => {
    const currentSlot = parseInt(event.target.getAttribute('data-index'), 10);
    const newDays = [];

    days.forEach((day, index) => {
      newDays.push(day);
      if (index === currentSlot) {
        newDays[index].startDate = event.target.value;
      }
    });

    setDays(newDays);
  }

  const updateEndDate = (event) => {
    const currentSlot = parseInt(event.target.getAttribute('data-index'), 10);
    const newDays = [];

    days.forEach((day, index) => {
      newDays.push(day);
      if (index === currentSlot) {
        newDays[index].endDate = event.target.value;
      }
    });

    setDays(newDays);
  }

  const updateText = (event) => {
    const currentSlot = parseInt(event.target.getAttribute('data-index'), 10);
    const newDays = [];

    days.forEach((day, index) => {
      newDays.push(day);
      if (index === currentSlot) {
        newDays[index].dateAndTime = event.target.value;
      }
    });

    setDays(newDays);
  }

  const addDay = (event) => {
    const newDays = [];

    days.forEach(link => {
      newDays.push(link);
    });

    newDays.push({
      startDate: moment().format('YYYY-MM-DDTHH:mm'),
      dateAndTime: moment().format('YYYY-MM-DDTHH:mm'),
      ticketOptions: [
        {
          key: 'adult',
          text: 'Adult',
          price: 12,
        },
        {
          key: 'senior',
          text: 'Senior (60 & over)',
          price: 10,
        },
        {
          key: 'child',
          text: 'Child (12 & under)',
          price: 7,
        },
      ],
      endDate: moment().format('YYYY-MM-DDTHH:mm')
    })

    setDays(newDays);
  }

  const deleteDay = (index) => {
    const newDays = [];

    days.forEach((day, thisIndex) => {
      if (index !== thisIndex) {
        newDays.push(day);
      }
    });

    setDays(newDays);
  }

  return (
    <Container>
      <Card className={classes.card}>
        <Grid
          container
          justifyContent='center'
          alignItems='center'
        >
          <Grid item xs={12}>
            <TextField
              label="Show Title (Only used in meta data)"
              className={classes.textField}
              variant="outlined"
              value={name}
              onChange={event => setName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <ImageUploader
              className={classes.textField}
              value={poster && `${config.api}/${poster}`}
              onChange={uploadImage}
              loading={loading}
              accessToken={accessToken}
            />
          </Grid>
          <Grid item xs={12}>
            <ReactQuill theme='snow' placeholder='Description' className={classes.textField} value={description} onChange={setDescription} disabled={loading} />
          </Grid>
          {days.map((link, index) => (
            <Grid key={index} item sm={12} md={6}>
              <Card className={classes.linkCard}>
                <Button variant='contained' color='secondary' onClick={() => deleteDay(index)} className={classes.deleteButton} startIcon={<DeleteIcon />}>Delete</Button>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    label='Start Time'
                    type='datetime-local'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900,
                      'data-index': index
                    }}
                    value={moment(link.startDate).tz('America/Chicago').format('YYYY-MM-DDTHH:mm')}
                    onChange={updateStartDate}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12}>
                <TextField
                    className={classes.textField}
                    label="Show date and time"
                    type='datetime-local'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900,
                      'data-index': index
                    }}
                    value={moment(link.dateAndTime).tz('America/Chicago').format('YYYY-MM-DDTHH:mm')}
                    onChange={updateText}
                    disabled={loading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    data-index={index}
                    label='End Time'
                    type='datetime-local'
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 900,
                      'data-index': index
                    }}
                    value={moment(link.endDate).tz('America/Chicago').format('YYYY-MM-DDTHH:mm')}
                    onChange={updateEndDate}
                    disabled={loading}
                  />
                </Grid>
              </Card>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button variant='contained' color='primary' onClick={addDay} className={classes.textField}>Add Date</Button>
          </Grid>
          {
            ticket === undefined ? (
              <Grid item xs={12}>
                {loading ? <Loader size={36} /> : <Button variant='contained' color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                {error ? <Error message={'Problem Saving Ticket'} alignment={'center'} /> : ''}
              </Grid>
            ) : (
              <Grid item xs={12}>
                {loading ? <Loader size={36} /> : <Button variant='contained' color='primary' onClick={onUpdate} className={classes.textField} >Update</Button>}
                {error ? <Error message={'Problem Saving Ticket'} alignment={'center'} /> : ''}
              </Grid>
            )
          }
        </Grid>
      </Card>
    </Container>
  );
}

const style = {
  card: {
    background: 'rgb(230, 230, 230)'
  },
  linkCard: {
    padding: 20,
    margin: 10,
  },
  textField: {
    margin: 20
  },
  deleteButton: {
    marginTop: 30,
  },
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.accessToken,
    ticket: state.tickets.byIds[state.tickets.current],
    next: state.tickets.allIds.length,
    loading: state.tickets.loading,
    error: state.tickets.error,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  addTicketAction,
  addTicketSuccessAction,
  addTicketFailureAction,
  editTicketAction,
  editTicketSuccessAction,
  editTicketFailureAction,
})(withStyles(style)(NewTicket));

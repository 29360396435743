import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import UpcomingCard from '../../Component/UpcomingCard';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setUpcomingsAction,
  setUpcomingsSuccessAction,
  setUpcomingsFailureAction,
  setCurrentUpcomingAction,
  setSaveUpcomingOrderAction,
  setSaveUpcomingOrderSuccessAction,
  setSaveUpcomingOrderFailureAction,
} from '../../Redux/Actions/upcomings';
import DragAndDrop from '../DragAndDrop';

function Upcoming(props) {
  const {
    accessToken,
    fetched,
    upcomings,
    loading,
    error,
    setScreenAction,
    setUpcomingsAction,
    setUpcomingsSuccessAction,
    setUpcomingsFailureAction,
    setCurrentUpcomingAction,
    setSaveUpcomingOrderAction,
    setSaveUpcomingOrderSuccessAction,
    setSaveUpcomingOrderFailureAction,
  } = props;

  const [localUpcomings, setLocalUpcomings] = useState(upcomings);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalUpcomings(upcomings);
  }, [upcomings]);

  useEffect(() => {
    if (!fetched) {
      setUpcomingsAction();
      fetch(`${config.api}/api/upcomings`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setUpcomingsSuccessAction(result.data);
          },
          (error) => {
            setUpcomingsFailureAction(error);
          }
        );
    }
  }, [fetched, setUpcomingsAction, setUpcomingsFailureAction, setUpcomingsSuccessAction]);

  const newUpcoming = () => {
    setCurrentUpcomingAction(null);
    setScreenAction('new_upcoming');
  }

  const saveOrder = () => {
    setSaveUpcomingOrderAction();

    fetch(`${config.api}/api/upcomings/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ upcomingIds: localUpcomings }),
    })
      .then(res => res.json())
      .then(
        (result) => {
            if (result.error) {
                // result error
                setSaveUpcomingOrderFailureAction(result.error);
                return;
            }

            setSaveUpcomingOrderSuccessAction(localUpcomings);
            setShowOrderSaveButton(false);
        },
        (error) => {
            // connection errors
            setSaveUpcomingOrderFailureAction(error);
        }
    );
  }

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={newUpcoming}>New Upcoming</Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localUpcomings}
        setLocal={setLocalUpcomings}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='upcoming'
        Card={UpcomingCard}
      />
      {error ? <Error message='Could not get upcomings' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.upcomings.fetched,
    loading: state.upcomings.loading,
    error: state.upcomings.error,
    upcomings: state.upcomings.allIds,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setUpcomingsAction,
  setUpcomingsSuccessAction,
  setUpcomingsFailureAction,
  setCurrentUpcomingAction,
  setSaveUpcomingOrderAction,
  setSaveUpcomingOrderSuccessAction,
  setSaveUpcomingOrderFailureAction,
})(Upcoming);

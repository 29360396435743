import { Paper, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";

function RoleCard(props) {
	const { index, role, classes } = props;
	return (
		<Paper className={classes.card}>
			  <div>{index + 1}</div>
				<Typography>{role.name}</Typography>
		</Paper>
	)
}

const style = ({
	card: {
    marginTop: 25,
    padding: 20,
    background: 'rgb(230, 230, 230)',
  },
})

const mapStateToProps = (state, props) => {
	return {
		role: state.auditions.byIds[props.parentId].roles[props.id],
	}
}

export default connect(mapStateToProps)(withStyles(style)(RoleCard));

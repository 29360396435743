import {
    SET_MEMBERS,
    SET_MEMBERS_SUCCESS,
    SET_MEMBERS_FAILURE,

    SET_CURRENT_MEMBER,

    ADD_MEMBER,
    ADD_MEMBER_SUCCESS,
    ADD_MEMBER_FAILURE,

    EDIT_MEMBER,
    EDIT_MEMBER_SUCCESS,
    EDIT_MEMBER_FAILURE,

    DELETE_MEMBER,
    DELETE_MEMBER_SUCCESS,
    DELETE_MEMBER_FAILURE,

    SAVE_MEMBER_ORDER,
    SAVE_MEMBER_ORDER_SUCCESS,
    SAVE_MEMBER_ORDER_FAILURE,
} from '../actionTypes';

export const setMembersAction = () => ({
    type: SET_MEMBERS,
})

export const setMembersSuccessAction = members => ({
    type: SET_MEMBERS_SUCCESS,
    payload: {
        members
    }
})

export const setMembersFailureAction = error => ({
    type: SET_MEMBERS_FAILURE,
    payload: {
        error
    }
})

export const setCurrentMemberAction = uuid => ({
    type: SET_CURRENT_MEMBER,
    payload: {
        uuid
    }
})

export const deleteMemberAction = () => ({
    type: DELETE_MEMBER,
})

export const deleteMemberSuccessAction = member => ({
    type: DELETE_MEMBER_SUCCESS,
    payload: {
        member
    }
})

export const deleteMemberFailureAction = error => ({
    type: DELETE_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const addMemberAction = () => ({
    type: ADD_MEMBER,
})

export const addMemberSuccessAction = member => ({
    type: ADD_MEMBER_SUCCESS,
    payload: {
        member
    }
})

export const addMemberFailureAction = error => ({
    type: ADD_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const editMemberAction = () => ({
    type: EDIT_MEMBER,
})

export const editMemberSuccessAction = member => ({
    type: EDIT_MEMBER_SUCCESS,
    payload: {
        member
    }
})

export const editMemberFailureAction = error => ({
    type: EDIT_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const setSaveMemberOrderAction = () => ({
    type: SAVE_MEMBER_ORDER,
})

export const setSaveMemberOrderSuccessAction = ids => ({
    type: SAVE_MEMBER_ORDER_SUCCESS,
    payload: {
        ids,
    },
})
export const setSaveMemberOrderFailureAction = error => ({
    type: SAVE_MEMBER_ORDER_FAILURE,
    payload: {
        error,
    },
})

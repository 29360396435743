import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';

function Error(props) {
    return (
        <Container>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems={props.alignment}
            >
                <Typography className={props.classes.text}>{props.message}</Typography>
            </Grid>
        </Container>
    );
}

const style = {
    text: {
        color: 'red',
    },
};

export default withStyles(style)(Error);

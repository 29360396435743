import React, { useState } from 'react';
import { connect } from 'react-redux';
import ImageUploader from '../../Component/ImageUploader';
import Loader from '../../Component/Loader';
import config from '../../config';
import Error from '../../Component/Error';
import SaveIcon from '@material-ui/icons/Save';
import { Container, TextField, Grid, Card, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { setScreenAction } from '../../Redux/Actions/app';
import {
    addMemberAction,
    addMemberSuccessAction,
    addMemberFailureAction,
    editMemberAction,
    editMemberSuccessAction,
    editMemberFailureAction,
} from '../../Redux/Actions/members';

function NewMember(props) {
    const {
        classes,
        member,
        next,
        loading,
        error,
        accessToken,
        addMemberAction,
        addMemberSuccessAction,
        addMemberFailureAction,
        editMemberAction,
        editMemberSuccessAction,
        editMemberFailureAction,
        setScreenAction,
    } = props;

    const [name, setName] = useState(member ? member.name : '');
    const [email, setEmail] = useState(member ? member.email : '');
    const [position, setPosition] = useState(member ? member.position : '');
    const [bio, setBio] = useState(member ? member.bio : '');
    const [image, setImage] = useState(member ? member.image.replace(`${config.api}/`, '') : null);
    const [order, setOrder] = useState(member ? member.order : next);

    const onSave = () => {
        const payload = {
            name,
            email,
            position,
            bio,
            image,
            order,
        };

        addMemberAction();

        fetch(`${config.api}/api/member`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ member: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        addMemberFailureAction(result.error);
                        return;
                    }

                    setName('');
                    setEmail('');
                    setPosition('');
                    setBio('');
                    setImage(null)
                    setOrder(order + 1);

                    payload._id = result._id;
                    payload.image = result.image;

                    addMemberSuccessAction(payload);
                },
                (error) => {
                    // connection errors
                    addMemberFailureAction(error);
                }
            );
    }

    const onUpdate = () => {
        const payload = {
            _id: member._id,
            name,
            email,
            position,
            bio,
            image,
            order,
        };

        editMemberAction();

        fetch(`${config.api}/api/member/${member._id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'x-access-token': accessToken,
            },
            body: JSON.stringify({ member: payload }),
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.error) {
                        // result error
                        editMemberFailureAction(result.error);
                        return;
                    }

                    payload.image = result.image;

                    editMemberSuccessAction(payload);
                    setScreenAction('member');
                },
                (error) => {
                    // connection errors
                    editMemberFailureAction(error);
                }
            );
    }

    const uploadImage = (uploadPath) => {
        if (uploadPath) {
            setImage(uploadPath);
        }
    }

    return (
        <Container>
            <Card className={classes.card}>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item>
                        <ImageUploader
                            className={classes.textField}
                            value={image && `${config.api}/${image}?${new Date().getTime()}`}
                            onChange={uploadImage}
                            loading={loading}
                            accessToken={accessToken}
                        />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Name" className={classes.textField} value={name} onChange={event => setName(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Email" className={classes.textField} value={email} onChange={event => setEmail(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <TextField variant="outlined" label="Position" className={classes.textField} value={position} onChange={event => setPosition(event.target.value)} disabled={loading} />
                    </Grid>
                    <Grid item>
                        <ReactQuill theme="snow" placeholder="Bio" className={classes.textField} value={bio} onChange={setBio} disabled={loading} />
                    </Grid>
                    {
                        member === undefined ? (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onSave} className={classes.textField} startIcon={<SaveIcon />} >Save</Button>}
                                {error ? <Error message={'Problem Saving Member'} alignment={'center'} /> : ''}
                            </Grid>
                        ) : (
                            <Grid item>
                                {loading ? <Loader size={36} /> : <Button variant="contained" color='primary' onClick={onUpdate} className={classes.textField} >Update</Button>}
                                {error ? <Error message={'Problem Saving Member'} alignment={'center'} /> : ''}
                            </Grid>
                        )
                    }
                </Grid>
            </Card>
        </Container>
    );
}

const style = {
    card: {
        background: 'rgb(230, 230, 230)'
    },
    textField: {
        margin: 20
    }
}

const mapStateToProps = (state) => {
    return {
      accessToken: state.auth.accessToken,
      member: state.members.byIds[state.members.current],
      next: state.members.allIds.length,
      loading: state.members.loading,
      error: state.members.error,
    };
};

export default connect(mapStateToProps, {
    setScreenAction,
    addMemberAction,
    addMemberSuccessAction,
    addMemberFailureAction,
    editMemberAction,
    editMemberSuccessAction,
    editMemberFailureAction,
})(withStyles(style)(NewMember));

import {
    SET_CURRENT_CAST_MEMBER,

    SET_CAST_MEMBERS,
    SET_CAST_MEMBERS_SUCCESS,
    SET_CAST_MEMBERS_FAILURE,

    ADD_CAST_MEMBER,
    ADD_CAST_MEMBER_SUCCESS,
    ADD_CAST_MEMBER_FAILURE,

    EDIT_CAST_MEMBER,
    EDIT_CAST_MEMBER_SUCCESS,
    EDIT_CAST_MEMBER_FAILURE,

    DELETE_CAST_MEMBER,
    DELETE_CAST_MEMBER_SUCCESS,
    DELETE_CAST_MEMBER_FAILURE,

    SAVE_CAST_MEMBER_ORDER,
    SAVE_CAST_MEMBER_ORDER_SUCCESS,
    SAVE_CAST_MEMBER_ORDER_FAILURE,
} from '../actionTypes';

export const setCastMembersAction = () => ({
    type: SET_CAST_MEMBERS,
})

export const setCastMembersSuccessAction = (showId, castMembers) => ({
    type: SET_CAST_MEMBERS_SUCCESS,
    payload: {
        showId,
        castMembers,
    }
})

export const setCastMembersFailureAction = error => ({
    type: SET_CAST_MEMBERS_FAILURE,
    payload: {
        error,
    }
})

export const deleteCastMemberAction = () => ({
    type: DELETE_CAST_MEMBER,
})

export const deleteCastMemberSuccessAction = (castMember) => ({
    type: DELETE_CAST_MEMBER_SUCCESS,
    payload: {
        castMember,
    }
})

export const deleteCastMemberFailureAction = error => ({
    type: DELETE_CAST_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const addCastMemberAction = () => ({
    type: ADD_CAST_MEMBER,
})

export const addCastMemberSuccessAction = (castMember) => ({
    type: ADD_CAST_MEMBER_SUCCESS,
    payload: {
        castMember,
    }
})

export const addCastMemberFailureAction = error => ({
    type: ADD_CAST_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const editCastMemberAction = () => ({
    type: EDIT_CAST_MEMBER,
})

export const editCastMemberSuccessAction = (castMember) => ({
    type: EDIT_CAST_MEMBER_SUCCESS,
    payload: {
        castMember,
    }
})

export const editCastMemberFailureAction = error => ({
    type: EDIT_CAST_MEMBER_FAILURE,
    payload: {
        error
    }
})

export const setCurrentCastMemberAction = uuid => ({
    type: SET_CURRENT_CAST_MEMBER,
    payload: {
        uuid
    }
})

export const setSaveCastOrderAction = () => ({
    type: SAVE_CAST_MEMBER_ORDER
})

export const setSaveCastOrderSuccessAction = ids => ({
    type: SAVE_CAST_MEMBER_ORDER_SUCCESS,
    payload: {
        ids
    }
})

export const setSaveCastOrderFailureAction = error => ({
    type: SAVE_CAST_MEMBER_ORDER_FAILURE,
    payload: {
        error
    }
})

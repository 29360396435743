import {
  SET_CURRENT_MEMBER,

  SET_MEMBERS,
  SET_MEMBERS_SUCCESS,
  SET_MEMBERS_FAILURE,

  ADD_MEMBER,
  ADD_MEMBER_SUCCESS,
  ADD_MEMBER_FAILURE,

  EDIT_MEMBER,
  EDIT_MEMBER_SUCCESS,
  EDIT_MEMBER_FAILURE,

  DELETE_MEMBER,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,

  SAVE_MEMBER_ORDER,
  SAVE_MEMBER_ORDER_SUCCESS,
  SAVE_MEMBER_ORDER_FAILURE,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
    allIds: [],
    byIds: {},
    current: null,
    fetched: false,
    loading: false,
    deleting: false,
    error: null,
};

export default function membersReducer(state = initialState, action) {
  switch (action.type) {
    case SET_MEMBERS: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_MEMBERS_SUCCESS: {
      const { members } = action.payload;
      const { allIds, byIds } = process(members);

      return {
        ...state,
        allIds,
        byIds,
        loading: false,
        fetched: true,
        error: null,
      };
    }

    case SET_MEMBERS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case SET_CURRENT_MEMBER: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    case ADD_MEMBER: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_MEMBER_SUCCESS: {
      const { member } = action.payload;
      const newAllIds = [...state.allIds, member._id];
      const newByIds = { [member._id]: member, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_MEMBER: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_MEMBER_SUCCESS: {
      const { member } = action.payload;
      const newByIds = { ...state.byIds, [member._id]: member };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_MEMBER: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_MEMBER_SUCCESS: {
      const { member } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== member._id);
      const { [member._id]: deletedMember, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_MEMBER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SAVE_MEMBER_ORDER: {
      return {
        ...state,
        loading: true,
        error: null,
      }
    }
    case SAVE_MEMBER_ORDER_SUCCESS: {
      const { ids } = action.payload;
      return {
        ...state,
        allIds: [...ids],
        loading: false,
      }
    }

    case SAVE_MEMBER_ORDER_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      }
    }

    default:
      return state;
  }
}

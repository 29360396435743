import {
  SET_PHOTO_GALLERIES,
  SET_PHOTO_GALLERIES_SUCCESS,
  SET_PHOTO_GALLERIES_FAILURE,

  ADD_PHOTO_GALLERY,
  ADD_PHOTO_GALLERY_SUCCESS,
  ADD_PHOTO_GALLERY_FAILURE,

  EDIT_PHOTO_GALLERY,
  EDIT_PHOTO_GALLERY_SUCCESS,
  EDIT_PHOTO_GALLERY_FAILURE,

  DELETE_PHOTO_GALLERY,
  DELETE_PHOTO_GALLERY_SUCCESS,
  DELETE_PHOTO_GALLERY_FAILURE,

  SET_CURRENT_PHOTO_GALLERY,
} from "../actionTypes";
import { process } from '../../helper';

const initialState = {
  allIds: [],
  byIds: {},
  current: null,
  fetched: false,
  loading: false,
  deleting: false,
  error: null,
};

export default function photoGalleryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PHOTO_GALLERIES: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case SET_PHOTO_GALLERIES_SUCCESS: {
      const { photos } = action.payload;
      const { allIds, byIds } = process(photos);

      return {
        ...state,
        allIds,
        byIds,
        loading: false,
        fetched: true,
        error: null,
      };
    }

    case SET_PHOTO_GALLERIES_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case ADD_PHOTO_GALLERY: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case ADD_PHOTO_GALLERY_SUCCESS: {
      const { photo } = action.payload;
      const newAllIds = [...state.allIds, photo._id];
      const newByIds = { [photo._id]: photo, ...state.byIds };

      return {
        ...state,
        allIds: newAllIds,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case ADD_PHOTO_GALLERY_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case EDIT_PHOTO_GALLERY: {

      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case EDIT_PHOTO_GALLERY_SUCCESS: {
      const { photo } = action.payload;
      const newByIds = { ...state.byIds, [photo._id]: photo };

      return {
        ...state,
        byIds: newByIds,
        loading: false,
        error: null,
      };
    }

    case EDIT_PHOTO_GALLERY_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        loading: false,
        error,
      };
    }

    case DELETE_PHOTO_GALLERY: {

      return {
        ...state,
        deleting: true,
        error: null,
      };
    }

    case DELETE_PHOTO_GALLERY_SUCCESS: {
      const { photo } = action.payload;
      const newIds = state.allIds.filter(thisId => thisId !== photo._id);
      const { [photo._id]: deletedPhoto, ...remainingIds } = state.byIds;

      return {
        ...state,
        allIds: newIds,
        byIds: remainingIds,
        deleting: false,
        error: null,
      };
    }

    case DELETE_PHOTO_GALLERY_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        deleting: false,
        error,
      };
    }

    case SET_CURRENT_PHOTO_GALLERY: {
      const { uuid } = action.payload;

      return {
        ...state,
        current: uuid
      }
    }

    default:
      return state;
  }
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Container, Grid, Button } from '@material-ui/core';
import GoalCard from '../../Component/GoalCard';
import config from '../../config';
import Loader from '../../Component/Loader';
import Error from '../../Component/Error';
import { setScreenAction } from '../../Redux/Actions/app';
import {
  setGoalsAction,
  setGoalsSuccessAction,
  setGoalsFailureAction,
  setCurrentGoalAction,
  setSaveGoalOrderAction,
  setSaveGoalOrderSuccessAction,
  setSaveGoalOrderFailureAction,
} from '../../Redux/Actions/goals';
import DragAndDrop from '../DragAndDrop';

function Goals(props) {
  const {
    accessToken,
    fetched,
    goals,
    loading,
    error,
    setScreenAction,
    setGoalsAction,
    setGoalsSuccessAction,
    setGoalsFailureAction,
    setCurrentGoalAction,
    setSaveGoalOrderAction,
    setSaveGoalOrderSuccessAction,
    setSaveGoalOrderFailureAction,
  } = props;

  const [localGoals, setLocalGoals] = useState(goals);
  const [showOrderSaveButton, setShowOrderSaveButton] = useState(false);

  useEffect(() => {
    setLocalGoals(goals);
  }, [goals]);

  useEffect(() => {
    if (!fetched) {
      setGoalsAction();
      fetch(`${config.api}/api/goals`, {cache: 'no-store'})
        .then(res => res.json())
        .then(
          (result) => {
            setGoalsSuccessAction(result.data);
          },
          (error) => {
            setGoalsFailureAction(error);
          }
        );
    }
  }, [fetched, setGoalsAction, setGoalsFailureAction, setGoalsSuccessAction]);

  const newGoal = () => {
    setCurrentGoalAction(null);
    setScreenAction('new_goal');
  }

  const saveOrder = () => {
    setSaveGoalOrderAction();

    fetch(`${config.api}/api/goals/order`, {
      method: 'PATCH',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': accessToken,
      },
      body: JSON.stringify({ goalIds: localGoals }),
    })
      .then(res => res.json())
      .then(
        (result) => {
            if (result.error) {
                // result error
                setSaveGoalOrderFailureAction(result.error);
                return;
            }

            setSaveGoalOrderSuccessAction(localGoals);
            setShowOrderSaveButton(false);
        },
        (error) => {
            // connection errors
            setSaveGoalOrderFailureAction(error);
        }
    );
  }

  if (loading) {
    return (
      <Container>
        <Loader size={80} />
      </Container>
    )
  }

  const renderButtons = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-end'
        alignItems='flex-start'
        spacing={2}
      >
        {showOrderSaveButton && (
          <Grid item>
            <Button variant="contained" onClick={saveOrder}>Save Order</Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={newGoal}>New Goal</Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Container>
      {renderButtons()}
      <DragAndDrop
        local={localGoals}
        setLocal={setLocalGoals}
        setShowOrderSaveButton={setShowOrderSaveButton}
        droppableId='goal'
        Card={GoalCard}
      />
      {error ? <Error message='Could not get goals' alignment='center' /> : ''}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    accessToken: state.auth.accessToken,
    fetched: state.goals.fetched,
    loading: state.goals.loading,
    error: state.goals.error,
    goals: state.goals.allIds,
  };
};

export default connect(mapStateToProps, {
  setScreenAction,
  setGoalsAction,
  setGoalsSuccessAction,
  setGoalsFailureAction,
  setCurrentGoalAction,
  setSaveGoalOrderAction,
  setSaveGoalOrderSuccessAction,
  setSaveGoalOrderFailureAction,
})(Goals);

import React from 'react';
import { Container, Typography } from '@material-ui/core';

function EmptyState() {
	return (
		<Container>
			<Typography>
				Welcome to the Polo Theatre Admin. Choose a section from the bar above to start.
			</Typography>
		</Container>
	);
}

export default EmptyState;